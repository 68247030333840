import { Box, Input, Flex, Text, background } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { colors } from "../styles/theme";

const TimeInput = ({ onChange, time }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(0);

  useEffect(() => {
    const timeInSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;
    setTotalTimeInSeconds(timeInSeconds)
    onChange(timeInSeconds);
  }, [days, hours, minutes, seconds, onChange]);

  const  formatTime = (seconds) => {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }
  
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    console.log(seconds);
    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(remainingSeconds);
  }

  useEffect(() => {
    formatTime(time);
    // console.log(time);
  }, [time]);

  return (
    <Flex align="center">
      <Box flex={1} mr={4}>
        <Text>Days:</Text>
        <Input
          height={'50px'}
          bg={'white'}
          type="text"
          border={`2px solid ${colors.primaryColor}`}
          min={0}
          onChange={(e) =>{e.target.value.length > 0? setDays(parseInt(e.target.value, 10)) : setDays(0)}}
          value={days}
        />
      </Box>
      <Box flex={1} mr={4}>
        <Text>Hours:</Text>
        <Input
        height={'50px'}
        bg={'white'}
          type="text"
          min={0}
          border={`2px solid ${colors.primaryColor}`}
          max={24}
          onChange={(e) => {e.target.value.length > 0? setHours(parseInt(e.target.value, 10)) : setHours(0)}}
          value={hours}
        />
      </Box>
      <Box flex={1} mr={4}>
        <Text>Minutes:</Text>
        <Input
        height={'50px'}
        bg={'white'}
          type="text"
          border={`2px solid ${colors.primaryColor}`}
          _focus={{
            background: 'transparent'
          }}
          min={0}
          max={60}
          onChange={(e) => {e.target.value.length > 0? setMinutes(parseInt(e.target.value, 10)) : setMinutes(0) }}
          value={minutes}
        />
      </Box>
      <Box flex={1}>
        <Text>Seconds:</Text>
        <Input
          height={'50px'}
          bg={'white'}
          border={`2px solid ${colors.primaryColor}`}
          _focus={{
            background: 'transparent'
          }}
          type="text"
          min={0}
          max={60}
          onChange={(e) =>{e.target.value.length > 0?  setSeconds(parseInt(e.target.value, 10)) : setSeconds(0)}}
          value={seconds}
        />
      </Box>
    </Flex>
  );
};

export default TimeInput;
