import React, { useEffect, useState } from 'react'
import SubNav from '../../../../components/sub-nav';
import { Container, Heading, Select, Stack, Button, Input, FormControl,Text, Textarea, Spinner, InputGroup, InputRightElement, Box, HStack } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { CurrencyFormatter, ParseSeconds, ApprovalStatus } from '../../../../utils/common';
import TaskNav from '../../../../components/tasknav';
import { BsArrowRight } from 'react-icons/bs';
import { naira } from '../../../../assets/dashboard/utils';
import { colors } from '../../../../styles/theme';
import DurationInput from '../../../../components/DurationInput';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../../store/reducers/loading';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Template from './templates/Template';

const Create = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const navigate = useNavigate();
  const toast = useToast();
  const [tasks, setTasks] = useState([]);
  const [amount, setAmount] = useState(0);
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [maxTask, setMaxTask] = useState("");
  const [template, setTemplate] = useState("");
  const [amountPerTask, setAmountPerTask] = useState(0);
  const [maxTaskPerUser, setMaxTaskPerUser] = useState(0);
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [readyTemplate, setReadyTemplate] = useState(false);




  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/createTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          name: name,
          description: description,
          max_task: maxTask,
          amount_per_task: amountPerTask,
          max_task_per_user: maxTaskPerUser,
          password: password,
          execution_time_in_seconds: timeInSeconds
        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
     
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
   
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } 
      navigate(`/dashboard/tasks/tasks`)

    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const quillStyles = {
    backgroundColor: 'white', // Background color
    border: `2px solid ${colors.primaryColor}`, // Border color
    height: '300px', // Set the desired height
  };

  useEffect(() => {
    
  }, []);


  return (
    <div>
      <Wrapper>

        <SubNav title={'My Task'} />
        <TaskNav />
        <Container maxW={{ base: 'sm', md: 'md', lg: 'lg' }} my='2rem'>
          <Heading fontSize={'24px'} textAlign={'center'} fontWeight='700' fontFamily={'raleway'} mb='1.5rem'>Create New Task</Heading>
            <FormControl>
              <label>Select template</label>
              <Select 
               height={'50px'}
               bg={'white'}
               border={`2px solid ${colors.primaryColor}`}
               type="text"
               value={template}
               onChange={(e) => setTemplate(e.target.value)}
              >
                <option value="">Choose Template</option>
                <option value="followers">Get More Followers</option>
                <option value="likes">Get More Likes</option>
                <option value="repost">Get people to repost </option>
                <option value="manual">Others</option>
              </Select>
            </FormControl>

            {template != "manual" && template != "" && 
            <Template 
              amountPerTask={amountPerTask}
              setAmountPerTask={setAmountPerTask}
              setName={setName}
              setDescription={setDescription}
              setTimeInSeconds={setTimeInSeconds}
              setMaxTask={setMaxTask}
              setMaxTaskPerUser={setMaxTaskPerUser}
              setReadyTemplate={setReadyTemplate}
              template={template}
              isTemplate={readyTemplate}
              description={description}
            /> }
             {(template == "manual" ) && <>
             <form onSubmit={handleSubmit}>

           

           
          
         
            
            <FormControl>
              <label>Name</label>
              <Input
                height={'50px'}
                bg={'white'}
                border={`2px solid ${colors.primaryColor}`}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}

              />

            </FormControl>
         
            
            <FormControl mt='1.5rem'>
              <label>Description</label>
              <ReactQuill
              style={quillStyles} 
              value={description}
              onChange={(e) => {  setDescription(e) }}
              theme="snow" // Specify the theme (snow is a common theme)
              />
            

            </FormControl>

            <FormControl mt='1.5rem'>
              <label>Execution Time Per Task</label>
              <DurationInput onChange={(e) => setTimeInSeconds(e)} />
              <small>{ParseSeconds(timeInSeconds)}</small>
            </FormControl>

            <FormControl mt='1.5rem'>
              <label>Max Task Required</label>
              <Input
                height={'50px'}
                bg={'white'}
                border={`2px solid ${colors.primaryColor}`}
                type="text"
                value={maxTask}
                onChange={(e) => setMaxTask(e.target.value)}
              />

            </FormControl>

            <FormControl mt='1.5rem'>
              <label>Amount Per Task</label>
              <Input
                height={'50px'}
                bg={'white'}
                border={`2px solid ${colors.primaryColor}`}
                type="text"
                value={amountPerTask}
                onChange={(e) => setAmountPerTask(e.target.value)}
              />
            </FormControl>

            <FormControl mt='1.5rem'>
              <label>Maximum Task Per User</label>
              <Input
                height={'50px'}
                bg={'white'}
                border={`2px solid ${colors.primaryColor}`}
                type="text"
                value={maxTaskPerUser}
                onChange={(e) => setMaxTaskPerUser(e.target.value)}

              />
            </FormControl>
            

           

            
          </form>
          </>}
          {readyTemplate && <>
           
          <FormControl mt='1.5rem'>
              <InputGroup p={0} m={0}>
                <Input 
                  height={'50px'}
                  bg={'white'}
                  border={`2px solid ${colors.primaryColor}`}
                  type={showPassword ? 'text' : 'password'} 
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant='unstyled'
                  required />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() => setShowPassword((showPassword) => !showPassword)}>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
                </InputGroup>
              </FormControl> 
              <FormControl mt='1.5rem'>
          <HStack spacing={10}><Text>Total Cost:</Text><Text><b>{CurrencyFormatter(maxTask * amountPerTask)}</b></Text></HStack>
          </FormControl>
         
            <Stack spacing={10} pt={2}>
              {isLoading() ?

                <Button
                  loadingText="Loading"
                  size="lg"
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  <Spinner />
                </Button>
                :

                <Button
                  loadingText="Loading"
                  size="lg"
                  onClick={handleSubmit}
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Continue <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
                </Button>
              }

            </Stack>
            </>}
        </Container>




      </Wrapper>
    </div>
  )
}

export default Create



const Wrapper = styled.div`
  background: #F3F3F3;
  padding-bottom: 4rem;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    height: 60px;
    
    border-radius: 12px;
    border: 1px solid ${colors.textColor};
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 8px 10px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    width: 55px;
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }

  img {
    align-self: center;
    margin: 0 1rem;
  }
`;




