import React, { useEffect, useState, useRef } from 'react';
import {
  Menu,
  MenuButton,
  Button,
  Input,
  MenuList,
  MenuItem,
  VStack,
  Text,
} from '@chakra-ui/react';

const SearchableDropdown = ({ options, onSelect, defaultText, value }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef();

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getValueText = () => {
    let text = value && !isNaN(value) && options.length > 0 ? options.filter((x) => x.value == value)[0].label : defaultText;
    return text;
  };

  const handleSelect = (option) => {
    onSelect(option.value);
  };

  useEffect(() => {
    if (isInputFocused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputFocused]);

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  return (
    <VStack spacing={4}>
      <Menu>
        <MenuButton as={Button} onBlur={handleInputBlur}>
          {getValueText()}
        </MenuButton>
        <MenuList p={1.5}>
          <Input
            placeholder="Search..."
            value={searchTerm}
            _focus={{
              background: 'transparent'
            }}
            onChange={(e) => setSearchTerm(e.target.value)}
            ref={inputRef}
            onFocus={handleInputFocus}
          />
          <Text color="gray.300" fontSize="sm">
            {filteredOptions.length} results
          </Text>
          {filteredOptions.map((option) => (
            <MenuItem key={option.value} onClick={() => handleSelect(option)}>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </VStack>
  );
};

export default SearchableDropdown;
