import { useEffect, useState } from 'react'
import { Flex, Text, Box, Image, Container, Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled';
import { transact } from '../../../assets/dashboard';
import Cookies from 'js-cookie';
import { CurrencyFormatter, parseDate } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import { useNavigate } from 'react-router-dom';



const Transaction = () => {
    const loadingCount = useSelector((state) => state.loading.count);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = () => {
        // console.log(loadingCount);
        return loadingCount > 0;
    }
    const transatData = ['B-wallet fund', 'Transfer to B-wallet', 'Transfer to bank', 'B-wallet fund', 'Transfer to me']
    const [history, setHistory] = useState([])

    const handleGetData = async () => {
        try {
            dispatch(increment());
            const token = `Bearer ${Cookies.get('Token')}`
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/wallet/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            const data = await response.json();
            dispatch(decrement());
            setHistory(data.data.data)
            // // console.log(data.data.data)

        } catch (err) {
            dispatch(decrement());
            // Handle errors
            // console.log(err)
            if (err.response && err.response.status === 401) {
                // Redirect to the login page if the API returns a 401 error
               navigate("/auth/login");
            }
        }
    }
    useEffect(() => {
        // // console.log(history)
        handleGetData();
        // // console.log(history)
    }, []);
    return (
        <Wrapper>
            <Container maxW={'860px'} gap='1.4rem'>
                <Flex mt='1rem' gap={2} align='center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                        <path d="M20.2891 10.5C20.2891 15.4706 16.2596 19.5 11.2891 19.5C6.3185 19.5 2.28906 15.4706 2.28906 10.5C2.28906 5.52944 6.3185 1.5 11.2891 1.5C16.2596 1.5 20.2891 5.52944 20.2891 10.5Z" stroke="#B8B8B8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M11.2891 5.09961V10.4996L14.8891 12.2996" stroke="#B8B8B8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <Text color={'#292A2F'}>Recent Transactions</Text>
                </Flex>

                <Box mt={'1.7rem'}>
                    {isLoading() &&
                        <Spinner />
                    }
                    {history.map((item, index) => {
                        

                        return (
                            <Flex mb={'1rem'} align={'ce'} shadow={'lg'} rounded={'lg'} padding={'0.7rem 0.9rem'} bg={'#fff'} justifyContent={'space-between'} key={index}>
                                <Flex gap={5}>
                                    <Box bg={'#42A4D9'} rounded='10px' padding="10px">
                                        <Image src={transact} alt='icon' />
                                    </Box>
                                    <Box>
                                        <Text width="80%§">{item.description}</Text>
                                        <Text>{parseDate(item.created_at)}</Text>
                                    </Box>
                                </Flex>
                                <Box>
                                    <Text>{CurrencyFormatter(item.amount)}</Text>
                                    <Text>{item.type}</Text>
                                </Box>
                            </Flex>
                        );
                    })}
                </Box>

            </Container>
        </Wrapper>
    )
}

export default Transaction

const Wrapper = styled.div`
    background: #F3F3F3;
    padding: 1rem 0;
    padding-bottom: 4rem;
`;