import styled from "styled-components";
import {colors} from "./theme";

import background from "../assets/background.svg";

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column;
  }
  
`;

export const MainHero = styled.div`
  width: 50%;
  
  padding: 20px 25px;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const Links = styled.div`
  display: flex;
  gap: 8px;
  
  a {
    padding: 6px 0;
    color: ${colors.textColor};
    
    &:hover {
      border-bottom: 1.5px solid ${colors.primaryColor};
      color: ${colors.primaryColor};
    }
  }
`;

export const Image = styled.div`
	width: 120px;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
	}

	@media (max-width: 992px) {
		width: 100px;
		height: 35px;
	}
`;

export const HeroContent = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  
  padding: 6rem 0;
  
  gap: 10px;
  
  @media (max-width: 992px) {
    padding: 3rem 0;
    /* display: none; */
  }
  
  h2 {
    color: ${colors.darkColor};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
  }
  p {
    color: ${colors.textColor};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%; /* 31.5px */

    @media (max-width: 992px) {
      font-size: 16px;
    }
  }
  
  .bills {
    margin-top: 10px;
    color: ${colors.textColor};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 175%; /* 31.5px */

    @media (max-width: 992px) {
      display: none;
    }
  }
`;

// ======= newsletter form styling =======//
export const Newsletter = styled.div`
    
  h3{
    color: ${colors.textColor};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 175%; /* 31.5px */
    
    margin-bottom: 8px;
  }
  
  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 10px;
  }
  
  svg {
    color: ${colors.primaryColor};
  }
  
  input {
    background: transparent;
    border: none;
  }
  input:focus {
    border: none;
    outline: none;
  }
  a {
    border-radius: 10px;
    padding: 5px 18px;
    background: ${colors.primaryColor};
    color: ${colors.lightColor};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 992px) {
      padding: 5px 10px;
    }
  }
`;




// ======= side hero section ======== //

export const SideHero = styled.div`
  /* padding: 20px 25px; */
  width: 50%;
  
  display: flex;
  justify-content: flex-end;
  align-items: start;
  flex-direction: row;

  @media (max-width: 992px) {
    width: 100%;
  }
  /* background-image: url('${background}');
  background-size: cover;
  background-position: center; */
  
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    color: ${colors.primaryColor};
  }
`;