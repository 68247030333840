import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components';
import { colors } from '../../../../../styles/theme';
import 'react-quill/dist/quill.snow.css';
import { Box, Button, Container, FormControl, Input, Select, Stack, Table, Tr } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Template = ({setAmountPerTask, setName,
    setDescription,
    setTimeInSeconds,
    setMaxTask,
    setMaxTaskPerUser, setReadyTemplate, template, isTemplate, description}) => {


        const quillStyles = {
            backgroundColor: 'white', // Background color
            border: `2px solid ${colors.primaryColor}`, // Border color
            height: '300px', // Set the desired height
          };
        
    const templateInformation = {
        "followers": {
            "topic": "Follow",
            "step2": "followers"
          
       
        },
        "likes": {
            "topic": "Like",
            "step2": "likes"
          
          
        
        },
        "repost": {
            "topic": "Re-share",
            "step2": "re-shares"
          
          
        
        }
    }

    


    const [platform, setPlatorm] = useState("");
    const [socialMediaLink, setSocialMediaLink] = useState("");
    const [noOfPerFollower, setNoOfFollower] = useState("");
    const [richText, setRichText] = useState("");
    const [target1, setTarget1] = useState("");

    const handleNameChange = () => {
        let newName = `Follow me on ${platform}`;
        setName(newName);
    }

    const handleDescriptionChange = () => {
        let selected = templateInformation[template];
        let description = 
        `<h2><center><b>${selected["topic"]} my ${platform} ${target1}</b></center></h2>`+
        `<p>In order to complete this task, you will have to carry out the following Steps</p><br/>`+
        `<div>`+
        `<p><b>Steps:</b></p>`+
        `<ol>`+
            `<li>Visit this page <a style="color: blue;" href="${socialMediaLink}" target="_blanck">${socialMediaLink}</a></li>`+
            `<li>${selected["topic"]} the ${target1} with your account</li>`+
            `<li>Take a screen shot</li>`+
            `<li>Submit the screenshot as proof</li>`+
        `</ol>`+
        `</div>`
        ;
        setDescription( description);
        setRichText(description);
     
    }

    const isValidUrl = (url) => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
      }

    const showBtn = () => {
        if(!isValidUrl(socialMediaLink)) return false;
        if(isNaN(noOfPerFollower) || noOfPerFollower < 1) return false;
        if( platform.length < 1) return false;
        if(template == "likes" && target1.length < 1) return false;
        return true;
    }

    const isTemplateReady = () => {

        setReadyTemplate(true);
    }

    useEffect(() => {
        setTimeInSeconds(15*60);
        setMaxTaskPerUser(1);
        setAmountPerTask(50);
        setAmountPerTask(100);
        setReadyTemplate(false);
        

    }, []);

    useEffect(() => {
        handleNameChange()
        handleDescriptionChange();
        setReadyTemplate(false)

    }, [platform,template]);

    useEffect(() => {
        handleDescriptionChange()
        setReadyTemplate(false)
        // console.log(isTemplate)
    }, [platform,socialMediaLink,template,target1]);


    return (
        <>
            <FormControl marginTop={'10px'}>
                <label>Which Platform</label>
                <Select
                    height={'50px'}
                    bg={'white'}
                    border={`2px solid ${colors.primaryColor}`}
                    type="text"
                    value={platform}
                    onChange={(e) => setPlatorm(e.target.value)}
                >
                    <option value="">Select Option</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Twitter">Twitter</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Linkedin">LinkedIn</option>
                    <option value="Youtube">YouTube</option>
                    <option value="Whatsapp">WhatsApp</option>
                    <option value="Snapchat">Snapchat</option>
                    <option value="Tiktok">TikTok</option>
                    <option value="Telegram">Telegram</option>
                </Select>


            </FormControl>
            
            <FormControl marginTop={'10px'}>
                <label>What would you like to get more {templateInformation[template]["step2"]} on (Page, Post)</label>
                <Input
                    height={'50px'}
                    bg={'white'}
                    border={`2px solid ${colors.primaryColor}`}
                    type="url"
                    value={target1}
                    onChange={(e) => setTarget1(e.target.value)}
                />
            </FormControl>
    

            <FormControl marginTop={'10px'}>
                <label>Link to {target1}</label>
                <Input
                    height={'50px'}
                    bg={'white'}
                    border={`2px solid ${colors.primaryColor}`}
                    type="url"
                    value={socialMediaLink}
                    onChange={(e) => setSocialMediaLink(e.target.value)}
                />
                {!isValidUrl(socialMediaLink) && <small style={{color : 'red'}}>Enter a valid URl (https://example.com)</small> }
            </FormControl>
           
            <FormControl marginTop={'10px'}>
                <label>How many {templateInformation[template]["step2"]} do you want?</label>
                <Input
                    height={'50px'}
                    bg={'white'}
                    border={`2px solid ${colors.primaryColor}`}
                    type="text"
                    value={noOfPerFollower}
                    onChange={(e) => {setNoOfFollower(e.target.value); setMaxTask(e.target.value)} }
                />
            </FormControl>

            <FormControl mt='1.5rem'>
              <label>Description</label>
              <ReactQuill
                style={quillStyles} 
                value={description}
                onChange={(e) => { setDescription(e) }}
                theme="snow" 
              />
             <div style={{color : 'red'}}>
                <u>Note</u><br />
                <p>Changes made can be lost when you make changes in other fields</p>
                </div> 

            </FormControl>
            
            
            {!isTemplate && showBtn() && <Stack spacing={10} pt={2}>
                <Button
                    onClick={isTemplateReady}
                    loadingText="Loading"
                    size="lg"
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                        bg: 'blue.500',
                    }}>
                        Verify
                    </Button>
            </Stack>}
        </>
    )
}

export default Template



const Wrapper = styled.div`
  background: #F3F3F3;
  padding-bottom: 4rem;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    height: 60px;
    
    border-radius: 12px;
    border: 1px solid ${colors.textColor};
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 8px 10px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    width: 55px;
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }

  img {
    align-self: center;
    margin: 0 1rem;
  }
`;




