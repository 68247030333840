import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Airtime from '../pages/dasboard/utils/airtime'
import DataRecharge from '../pages/dasboard/utils/data'
import Sme from '../pages/dasboard/utils/sme'
import Electricity from '../pages/dasboard/utils/electricity'
import Cable from '../pages/dasboard/utils/cable'
import Transfer from '../pages/dasboard/utils/transfer'
import WalletTransfer from '../pages/dasboard/utils/wallet'

function UtilsRoutes() {
  return (
    <Routes>
        <Route path='/airtime' element={<Airtime />} />
        <Route path='/data' element={<DataRecharge />}/>
        <Route path='/sme' element={<Sme />}/>
        <Route path='/electricity' element={<Electricity />}/>
        <Route path='/cable' element={<Cable />} />
        <Route path='/transfer' element={<Transfer />} />
        <Route path='/wallet-transfer' element={<WalletTransfer />} />
    </Routes>
  )
}

export default UtilsRoutes