'use client'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  Link,
  Button
} from '@chakra-ui/react'
import { sutaIcon } from '../../assets/dashboard'
import { Wrapper } from './style'
import { colors } from '../../styles/theme';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { FaBell } from 'react-icons/fa'
import UserDetails from './user-details'

const Links = ['Home', 'Transactions', 'My Account', 'Settings']
const url = [ '/dashboard', '/dashboard/transactions', '/dashboard/my-account', '/dashboard/settings' ]

const NavLink = ({ children, index }) => {
    return (
      <Box
        as="a"
        px={2}
        py={1}
        rounded={'md'}
        color='#fff'
        _hover={{
          textDecoration: 'none',
          fontWeight: 600,
          cursor: 'pointer'
          // bg: useColorModeValue('gray.600', 'gray.700'),
        }}
        href={url[index]}>
        {children}
      </Box>
    )
  }

export default function () {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear the authentication token
        localStorage.removeItem('token');
        Cookies.remove('Token');
        
        // Redirect to the login page
        navigate("/auth/login"); // Assuming "/login" is the route for the login page
    };

    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Flex h={32} alignItems={'center'} justifyContent={'space-between'}>
            <Image src={sutaIcon} alt='logo' onClick={() => navigate("/dashboard")}  />
            <HStack spacing={8} justifyContent={'space-between'} alignItems={'center'}>
                <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }} color='#fff'>
                {Links.map((link, index) => (
                    <NavLink key={index} index={index}>{link}</NavLink>
                    ))}
                </HStack>
            </HStack>
            <Flex gap={'10px'} alignItems='center'>
                <Button
                  p={'8px 15px'}
                  bg={`${colors.lightColor}`}
                  color={`${colors.primaryColor}`}
                  border='none'
                  onClick={handleLogout}
                  _hover={{
                      bg: `${colors.primaryColor}`,
                      color: `${colors.lightColor}`
                  }}
                >
                    Log out
                </Button>
                <Link href='/dashboard/notifications' bg={'#fff'} borderRadius='8px' p={'12px 18px'}>
                  <FaBell />
                </Link>
                <IconButton
                  size={'md'}
                  border='none'
                  icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                  aria-label={'Open Menu'}
                  display={{ md: 'none' }}
                  onClick={isOpen ? onClose : onOpen}
                />
            </Flex>
            </Flex>
            {isOpen ? (
                <Box pb={4} display={{ md: 'none' }}>
                  <Stack as={'nav'} spacing={4}>
                      {Links.map((link, index) => (
                      <NavLink key={index} index={index}>{link}</NavLink>
                      ))}
                  </Stack>
                </Box>
            ) : null}
          </>
    )
}