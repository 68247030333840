import React from 'react'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Container, Grid, Flex, Text, Stack, Button } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { referral, settings, support, userImg } from '../../../assets/dashboard'
import Navbar from './navbar'
import DashboardFooter from '../../../components/dashboard-footer'
import { colors } from '../../../styles/theme'
import { BsBoxArrowInLeft } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'
import { decrement, increment } from '../../../store/reducers/loading';
import { useDispatch, useSelector } from 'react-redux';

const help = [
    {
        name: 'support',
        img: support,
        url: '/dashboard/support',
    },
    {
        name: 'referrals',
        img: referral,
        url: '/dashboard/referral',
    },
    {
        name: 'settings',
        img: settings,
        url: '/dashboard/settings',
    }
]
const Account = () => {
    const loadingCount = useSelector((state) => state.loading.count);
    const dispatch = useDispatch();
    const isLoading = () => {
  
      // console.log(loadingCount);
      return loadingCount > 0;
    }
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [profileImg, setProfileImg] = useState(null)

    const navigate = useNavigate();
  
    const handleGetData = async () => {
        try {
            dispatch(increment());
            const token = `Bearer ${Cookies.get('Token')}`
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
              },
            });
      
            if(response.status == 401)  {
                navigate("/auth/login");
            }
            const data = await response.json();
            
            dispatch(decrement());
            setFirstName(data.data.user.first_name);
            setLastName(data.data.user.last_name);
            setPhoneNumber(data.data.user.phone_number);
            setEmail(data.data.user.email)
            setProfileImg(data.data.user.profile_image)
            // // console.log(data)
    
      
          } catch (err) {
            // Handle errors
           
            if (err.response && err.response.status === 401) {
                // Redirect to the login page if the API returns a 401 error
               navigate("/auth/login");
              }
        }
    }
    useEffect(() => {
      handleGetData();
    }, []);

    const handleLogout = () => {
        // Clear the authentication token
        localStorage.removeItem('token');
        Cookies.remove('Token');
        
        // Redirect to the login page
        navigate("/auth/login"); // Assuming "/login" is the route for the login page
    };
      
  return (
    <div>
        <Navbar />
        <Container maxW={'lg'}>
            <Flex m='1rem 2rem' justify={'center'} align={{ base: 'center', lg: 'start' }} gap={'3rem'} flexDir={{ base: 'column', lg: 'row' }} >
                <ImgWrapper>
                    <img src={profileImg == null ? userImg : profileImg} alt='profile' />
                    <h3>{firstName} {lastName}</h3>
                    <p className='phone-number'>{phoneNumber}</p>
                    <p className='email'>{email}</p>


                    <Stack spacing={4} pt={8}>
                        <Button
                        as={'a'}
                        href='/dashboard/edit-profile'
                        size="lg"
                        bg={`${colors.lightColor}`}
                        color={`${colors.primaryColor}`}
                        _hover={{
                            bg: `${colors.primaryColor}`,
                            color: `${colors.lightColor}`
                        }}>
                        <FiEdit style={{ fontSize: '22px', marginRight: '.7rem', fontWeight: '600' }} /> Edit Profile
                        </Button>
                        <Button
                            size="lg"
                            bg={`${colors.primaryColor}`}
                            color={`${colors.lightColor}`}
                            onClick={handleLogout}
                            _hover={{
                                bg: 'blue.500',
                            }}>
                         <BsBoxArrowInLeft style={{ fontSize: '22px', marginRight: '.7rem', fontWeight: '600' }} /> Log out
                        </Button>
                    </Stack>
                </ImgWrapper>

                <Grid gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }} gap='1rem'>
                    {help.map((item, index) => (
                        <HelpBox key={index} href={item.url}> 
                            <img src={item.img} alt={item.name}/>
                            <Text fontWeight={'600'} textTransform='capitalize'>{item.name}</Text>
                        </HelpBox>
                    ))}
                </Grid>
            </Flex>
        </Container>
        <DashboardFooter />
    </div>
  )
}

export default Account

const ImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
    text-transform: capitalize;

    @media(max-width: 992px) {
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    img {
        width: 182px;
        height: 184px;
        border-radius: 50%;
        
        @media(max-width: 992px) {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }
    h3 {
        color: #292A2F;
        text-align: center;
        font-family: Inter;
        font-size: 34px;
        font-weight: 400;
        line-height: 42px; /* 144.186% */

        @media(max-width: 992px) {
            font-size: 28px;
        }
    }
    .phone-number {
        color: #292A2F;
        text-align: center;
        font-family: Inter;
        font-size: 25px;
        font-weight: 400;

        @media(max-width: 992px) {
            font-size: 18px;
        }
    }
    .email {
        color: #292A2F;
        text-align: center;
        font-family: Inter;
        font-size: 17px;
        font-weight: 400;
        text-transform: lowercase;

        @media(max-width: 992px) {
            font-size: 15px;
        }
    }
`;

const HelpBox = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 1.5rem;
    padding: 1.5rem 1rem;

    width: 163px;
    height: 180px;
    border-radius: 24px;
    background: ${colors.lightColor};
    cursor: pointer;

    &:hover {
        background: ${colors.primaryColor};
        transition: .3s all ease-in-out;
        color: ${colors.lightColor};
    }
`;