import { BsInfoSquareFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { BiSolidHome } from 'react-icons/bi'

export const SidebarData = [
	{
		title: "Home",
		path: "/",
		icon: <BiSolidHome fontSize={"1.6rem"} />,
	},
	{
		title: "About us",
		path: "#",
		icon: <BsInfoSquareFill fontSize={"1.6rem"} color='#000' />,
	},
	{
		title: "Privacy Policy",
		path: "#",
		icon: <FaUsers fontSize={"1.6rem"} />,
	},
];