import React, { useState } from 'react'
import SubNav from '../../../components/sub-nav'
import { sizes } from '../../../styles/theme'
import { Container, Flex, Text, Switch, Button, InputRightElement, Input, Stack, Spinner, InputGroup, Heading } from '@chakra-ui/react'
import { colors } from '../../../styles/theme'
import { settings } from './data'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { styled } from 'styled-components';
import { BsArrowRight } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { decrement, increment } from '../../../store/reducers/loading';
import { useToast } from '@chakra-ui/react';
import Cookies from 'js-cookie'


const ChangePassword = () => {
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
  const loadingCount = useSelector((state) => state.loading.count);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!password && !newPassword && !confirmNewPassword) {
      return toast.error("please provide all values");
    }
    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          old_password: password,
          password: newPassword,
          password_confirmation: confirmNewPassword
        }), // Convert the form data to JSON
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
     
        toast({
          title: data?.message,
          status: 'success',
          description: data?.message,
          isClosable: true,
        })
        navigate("/dashboard/settings");
      }
    } catch (err) {
      dispatch(decrement());
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }


  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  return (
    <div>
        <SubNav title={'Settings'} />
        <Container mt='2rem' placeContent={'center'} maxWidth={'lg'}>
        <Heading fontSize={'24px'} fontWeight='700' fontFamily={'inter'}>Change Password</Heading>
        <form onSubmit={handleSubmit}>
         
     

         <label>Old Password</label>
         <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
           <InputGroup p={0} m={0}>
             <Input 
               style={{"flex": "1"}}
               type={showPassword ? 'text' : 'password'} 
               placeholder="Old Password"
               value={password}
               _focus={{
                 border: 'none',
                 outline: 'none',
                 background: 'transparent'
               }}
               onChange={(e) => setPassword(e.target.value)}
               variant='unstyled'
               required />
             <InputRightElement h={'full'}>
               <Button
                 variant={'ghost'}
                 _focus={{
                   border: 'none',
                   outline: 'none',
                   background: 'transparent'
                 }}
                 _hover={{
                   background: 'transparent'
                 }}
                 onClick={() => setShowPassword((showPassword) => !showPassword)}>
                 {showPassword ? <ViewIcon /> : <ViewOffIcon />}
               </Button>
             </InputRightElement>
           </InputGroup>
         </InputContainer>

         <label>New Password</label>
         <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
           <InputGroup p={0} m={0}>
             <Input 
               style={{"flex": "1"}}
               type={showNewPassword ? 'text' : 'password'} 
               placeholder="New Password"
               value={newPassword}
               _focus={{
                 border: 'none',
                 outline: 'none',
                 background: 'transparent'
               }}
               onChange={(e) => setNewPassword(e.target.value)}
               variant='unstyled'
               required />
             <InputRightElement h={'full'}>
               <Button
                 variant={'ghost'}
                 _focus={{
                   border: 'none',
                   outline: 'none',
                   background: 'transparent'
                 }}
                 _hover={{
                   background: 'transparent'
                 }}
                 onClick={() => setShowNewPassword((showPassword) => !showPassword)}>
                 {showNewPassword ? <ViewIcon /> : <ViewOffIcon />}
               </Button>
             </InputRightElement>
           </InputGroup>
         </InputContainer>

         <label>Confirm New Password</label>
         <InputContainer style={{ margin: '1.2rem 0', justifyContent: 'space-between' }}>
           <InputGroup p={0} m={0}>
             <Input 
               style={{"flex": "1"}}
               type={showConfirmNewPassword ? 'text' : 'password'} 
               placeholder="Confirm New Password"
               value={confirmNewPassword}
               _focus={{
                 border: 'none',
                 outline: 'none',
                 background: 'transparent'
               }}
               onChange={(e) => setConfirmNewPassword(e.target.value)}
               variant='unstyled'
               required />
             <InputRightElement h={'full'}>
               <Button
                 variant={'ghost'}
                 _focus={{
                   border: 'none',
                   outline: 'none',
                   background: 'transparent'
                 }}
                 _hover={{
                   background: 'transparent'
                 }}
                 onClick={() => setShowConfirmNewPassword((showPassword) => !showPassword)}>
                 {showConfirmNewPassword ? <ViewIcon /> : <ViewOffIcon />}
               </Button>
             </InputRightElement>
           </InputGroup>
         </InputContainer>

         <Stack spacing={10} pt={2}>
           {isLoading() ?
             <Button
               loadingText="Loading"
               size="lg"
               bg={'blue.400'}
               border='none'
               color={'white'}
               _hover={{
                 bg: 'blue.500',
               }}>
               <Spinner />
             </Button>
             :
             <Button
               loadingText="Loading"
               size="lg"
               type='submit'
               border='none'
               bg={'blue.400'}
               color={'white'}
               _hover={{
                 bg: 'blue.500',
               }}>
               Continue <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
             </Button>
           }

         </Stack>
       </form>
        </Container>
    </div>
  )
}

export default ChangePassword


const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    height: 60px;
    
    border-radius: 12px;
    border: 1px solid ${colors.textColor};
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 8px 10px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    width: 55px;
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }

  img {
    align-self: center;
    margin: 0 1rem;
  }
`;