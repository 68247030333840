import React, { useEffect, useState } from 'react'
import SubNav from '../../../components/sub-nav';
import { Container, Heading, Select, Stack, Button, Input, Table, Tr, Th, Td, Thead, Tbody, Flex, TableContainer, Spinner, FormControl } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { ApprovalStatus, CurrencyFormatter, ParseSeconds, ReferenceGenerator } from '../../../utils/common';
import Pagination from '../../../components/pagination';
import TaskNav from '../../../components/tasknav';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import { colors } from '../../../styles/theme';


const Tasks = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const { settlementId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [subTasks, setSubTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [taskStatus, setTaskStatus] = useState("ALL");


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAvailableTask(newPage);
  }


  const getAvailableTask = async (pageNumber) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/getSubTasks?page=${pageNumber}${settlementId?.length > 0 ? '&settlement_id=' + settlementId : ''}${taskStatus != "ALL"? '&task_status=' + taskStatus : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setSubTasks(data.data.data)
      setLastPage(data.data.last_page)

    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  useEffect(() => {
    getAvailableTask(currentPage);
  }, []);


  return (
    <div>
      <Wrapper>
        <SubNav title={'Jobs'} />
        <TaskNav />
        <Flex justifyContent='center' alignItems='end' gap={'2rem'} mx='2rem'>
      
          <FormControl>
            <label style={{ fontWeight: '500' }}>Status</label>
            <Select onChange={(e) => setTaskStatus(e.target.value)} value={taskStatus} border={`1px solid ${colors.primaryColor}`} variant='filled' color={'#aaa'}>
              <option value="ALL">ALL</option>
              <option value="ACTIVE">ACTIVE</option>
              <option value="INACTIVE">INACTIVE</option>
            </Select>
          </FormControl>
        

          <FormControl>
            <Button
              loadingText="Loading"

              type='submit'
              bg={`${colors.primaryColor}`}
              color={'white'}
              mt={'10px'}
              _hover={{
                bg: 'blue.500',
              }}
              onClick={() => getAvailableTask(currentPage)}
            >Filter</Button>
          </FormControl>
          
        </Flex>
       
        <TableContainer mt='2rem' mx='2rem'>
          <Table variant="simple" colorScheme="blue"  >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Name</Th>
                <Th>Execution Time</Th>
                <Th>Amount</Th>
                <Th>Status</Th>
                <Th>Settlement Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading() && <Tr>
                <Spinner />
              </Tr>}
              {subTasks.map((subTask) => (

                <Tr key={subTask?.id}>
                  <Td>#{ReferenceGenerator(subTask?.id, subTask?.created_at)}</Td>
                  <Td>{subTask?.task?.name}</Td>
                  <Td>{ParseSeconds(subTask?.task?.execution_time_in_seconds)}</Td>
                  <Td>{CurrencyFormatter(subTask?.amount)}</Td>

                  <Td><ApprovalStatus status={subTask?.task_status} /></Td>
                  <Td><ApprovalStatus status={subTask?.settlement_status ? subTask?.settlement_status : "PENDING"} /></Td>

                  <Td>
                    <Stack direction={['column', 'row']} spacing={['1', '2']} align="center">
                      <Button
                        loadingText="Loading"
                        size="sm"
                        type='submit'
                        bg={'blue.400'}
                        color={'white'}
                        margin={'2px'}
                        _hover={{
                          bg: 'blue.500',
                        }}
                        onClick={() => navigate(`/dashboard/tasks/jobs/${subTask.id}/details`)}
                      >View</Button>
                    </Stack>
                  </Td>
                </Tr>
              ))}


            </Tbody>
          </Table>
        </TableContainer>

        <Pagination currentPage={currentPage} totalPages={lastPage} onPageChange={handlePageChange} />


      </Wrapper>
    </div>
  )
}

export default Tasks



const Wrapper = styled.div`
  background: #F3F3F3;
  padding-bottom: 4rem;
`;




