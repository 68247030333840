import React, { useEffect, useState } from 'react'
import SubNav from '../../../components/sub-nav';
import { Select, Button, Table, Tr, Th, Td, Thead, Tbody, FormControl, Flex, TableContainer, Spinner } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { CurrencyFormatter, ParseSeconds, ReferenceGenerator } from '../../../utils/common';
import Pagination from '../../../components/pagination';
import TaskNav from '../../../components/tasknav';
import { colors } from '../../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';


const Market = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const navigate = useNavigate();
  const toast = useToast()
  const [sortBy, setSortBy] = useState("created_at");
  const [order, setOrder] = useState("asc");
  const [status, setStatus] = useState("ALL");
  const [setttlements, setSettlements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getSettlements(newPage);
  }


  const getSettlements = async (pageNumber) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/settlements?status=${status}&page=${pageNumber}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setSettlements(data.data.data)
      setLastPage(data.data.last_page)


    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  useEffect(() => {
    getSettlements(currentPage);
  }, []);


  return (
    <div>
      <Wrapper>
        <SubNav title={'Market Place'} />
        <TaskNav />
        <Flex justifyContent='center' alignItems='end' gap={'2rem'} mx='2rem'>
          <FormControl>
            <label>Status</label>
            <Select onChange={(e) => setStatus(e.target.value)} value={sortBy}>
              <option value="ALL">ALL</option>
              <option value="PENDING">PENDING</option>
              <option value="SETTLED">SETTLED</option>
            </Select>
          </FormControl>

          <FormControl>
            <Button
              loadingText="Loading"

              type='submit'
              bg={`${colors.primaryColor}`}
              color={'white'}
              mt={'10px'}
              _hover={{
                bg: 'blue.500',
              }}
              onClick={() => getSettlements(currentPage)}
            >Filter</Button>
          </FormControl>

        </Flex>

        <TableContainer mt='2rem' mx='2rem'>
          <Table variant="simple" colorScheme="blue">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Amount</Th>
                <Th>Fees</Th>
                <Th>Jobs Count</Th>

              </Tr>
            </Thead>
            <Tbody>
              {isLoading() && <Tr>
                <Spinner />
              </Tr>}
              {setttlements.map((setttlement) => (

                <Tr key={setttlement?.id}>
                  <Td>#{ReferenceGenerator(setttlement?.id, setttlement?.created_at)}</Td>
                  <Td>{CurrencyFormatter(setttlement?.amount)}</Td>
                  <Td>{CurrencyFormatter(setttlement?.fees)}</Td>
                  <Td>{setttlement?.sub_setttlements?.length}</Td>

                  <Td>
                    <Button
                      loadingText="Loading"
                      size="sm"
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      onClick={() => navigate(`/dashboard/setttlements/jobs/${setttlement.id}`)}
                    >View Jobs</Button>
                  </Td>
                </Tr>
              ))}


            </Tbody>
          </Table>
        </TableContainer>

        <Pagination currentPage={currentPage} totalPages={lastPage} onPageChange={handlePageChange} />


      </Wrapper>
    </div>
  )
}

export default Market

const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;




