import { Box, Button, Image, Text, Card, Flex } from '@chakra-ui/react'
import React,  { useRef }from 'react'
import { successIcon, sutaIcon } from '../../assets/dashboard'
import { colors } from '../../styles/theme'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { styled } from 'styled-components';
import { CurrencyFormatter } from '../../utils/common';

const SuccessCard = ({ message, transaction }) => {



  const contentRef = useRef(null);

  const handleDownloadPDF = () => {
    if (!contentRef.current) return;

    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('download.pdf');
    });
  };



  const handleDownloadJPEG = () => {
    if (!contentRef.current) return;

    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'download.jpeg';
      link.click();
    });
  };

  const StyledTable = () => {
    return (
      <table style={{ width: '95%', borderCollapse: 'collapse', margin: 'auto'}}>
        <thead >
        <tr>
            <th style={{ backgroundColor: '#9ce3ff', border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>Reference</th>
            <td style={{ border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>{transaction?.reference}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#9ce3ff', border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>Bank Name</th>
            <td style={{ border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>{transaction?.bank?.name}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#9ce3ff', border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>Account Name</th>
            <td style={{ border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>{transaction?.account_name}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#9ce3ff', border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>Account Number</th>
            <td style={{ border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>{transaction?.account_number}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#9ce3ff', border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>Amount</th>
            <td style={{ border: '1px solid #42A4D9', textAlign: 'left', padding: '8px' }}>{CurrencyFormatter(transaction?.amount)}</td>
          </tr>
        </thead>
       
      </table>
    );
  };

  return (
    <Flex justify={'center'} align='center'>
        <Card 
            w={'550px'} 
            textAlign='center' 
            my={{ base: '2rem', lg: '4rem' }}
            boxShadow={'lg'} 
            borderRadius='lg'
            p='5'
            
            display='flex'
            justify={'center'}
            align='center'
            gap={'1rem'}
            flexDir={'column'}
        >
          <Image src={successIcon} alt='icon' w='150px' />
          <Text fontSize={'24px'} fontWeight='600' fontFamily={'inter'}>Congratulations!</Text>
          <Text fontSize={'16px'} fontWeight='400' fontFamily={'inter'}>{message}</Text>
          <div className='flex' ref={contentRef}>
            <ReceiptTitle>
              <span> <Image src={sutaIcon} alt='icon' w='35px' height={'35px'} /> SUTA</span>
              <p>Transaction Receipt</p>
            </ReceiptTitle>
            <StyledTable />
            <p style={{ textAlign: 'center', fontWeight: '400', fontSize: '15px', marginTop: '1rem', marginBottom: '1rem' }}>Contact: <span style={{ color: `${colors.primaryColor}` }}>support@suta.ng</span></p>
          </div>
          <Flex justifyContent={'center'} alignItems='center' gap='1rem'>
            <Button 
              as={'a'}
              href='/dashboard'
              fontFamily={'raleway'} 
              fontSize={{ base: '16px', lg: '18px' }}
              borderRadius='26px'
              height={'35px'}
            
              p='0 1.5rem'
              bg={`${colors.primaryColor}`}
              color={`${colors.lightColor}`}
              _hover={{ 
                opacity: .9
              }}
              >Go Home</Button>

            <Button 
              onClick={handleDownloadJPEG}
              fontFamily={'raleway'} 
              fontSize={{ base: '16px', lg: '18px' }}
              borderRadius='26px'
              height={'35px'}
            
              p='0 1.5rem'
              bg={`${colors.primaryColor}`}
              color={`${colors.lightColor}`}
              _hover={{ 
                opacity: .9
              }}
              >Download as JPEG</Button>
          </Flex>
        </Card>
  
    </Flex>
  )
}

export default SuccessCard

const ReceiptTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  padding: .5rem 0;
  margin-bottom: 1.5rem;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4rem;

    font-size: 20px;
    font-weight: 600;
  }
`;