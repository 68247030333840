import React from 'react'
import { useLocation } from 'react-router-dom';
import Power from '../components/success/power'
import Airtime from '../components/success/airtime'
import Data from '../components/success/data'
import Sme from '../components/success/sme'
import Cable from '../components/success/cable'
import Transfer from '../components/success/transfer'
import WalletTransfer from '../components/success/wallettransfer'
import Funded from '../components/success/funded'
import SubNav from '../components/sub-nav'

export default function SuccessPage() {
    const location = useLocation();
    const { state } = location;
   

    const SuccessTemplate = ({message, transaction, type}) => {
    
      switch(type) {
        case "power":
          return <Power message={message} transaction={transaction}  />
        case "airtime":
          return <Airtime message={message} transaction={transaction} />
        case "data":
          return <Data message={message} transaction={transaction} />
        case "sme":
          return <Sme message={message} transaction={transaction} />
        case "cable":
          return  <Cable message={message} transaction={transaction} />
        case "transfer":
          return <Transfer message={message} transaction={transaction} />
        case "wallet-transfer":
          return <WalletTransfer message={message} transaction={transaction} />
        case "funded":
          return <Funded message={message} transaction={transaction} />
      }
    }

  return (
    <div>
        <SubNav title={'Success'} />

        <SuccessTemplate message={state.data} transaction={state.transaction} type={state.type}  />
        
    </div>
  )
}
