import { colors } from "../../styles/theme";
import styled from "@emotion/styled";
import { dashNav } from "../../assets/dashboard";

export const Wrapper = styled.div`
  padding: 0 4rem;
  padding-bottom: 6rem;
  background-image: url('${dashNav}');
  background-size: cover;
  background-position: center;

  @media(max-width: 992px) {
    padding: 0 2rem;
    padding-bottom: 4rem;
  }
  

  .amount {
    color: #FFF;
    font-family: Inter;
    font-size: 35px;
    font-weight: 600;
    line-height: 63px; /* 172.917% */
    letter-spacing: 1.968px;

    @media(max-width: 992px) {
      font-size: 25px;
      line-height: 56px; 
    }
  }

  .wallet {
    color: ${colors.lightColor};
    font-family: Raleway;
    font-size: 22px;
    font-weight: 600;
    line-height: 38px; /* 165.217% */

    @media(max-width: 992px) {
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }

  .icon-wrapper {
    background: ${colors.lightColor};
    border-radius: 3px;
    padding: 8px;
    cursor: pointer;

    svg {
      font-size: 18px;
    }
  }

  .divider {
    width: 3px;
    height: 200px;
    background: ${colors.lightColor};

    @media(max-width: 992px) {
      display: none;
    }
  }

  .welcome {
    color: ${colors.lightColor};
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;

    @media(max-width: 992px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .question {
    color: ${colors.lightColor};
    /* BODY/xSMALL/regular */
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  .fund-wallet {
    display: flex;
    align-items: center;
    gap: 1rem;

    background: ${colors.lightColor};
    color: ${colors.primaryColor};
    border-radius: 3px;
    padding: 10px 15px;
    cursor: pointer;

    @media(max-width: 992px) {
      /* margin-bottom: 2rem; */
      padding: 10px 15px;
      font-size: 14px;

    }
    img {
      width: 30px;
      @media(max-width: 992px) {
        width: 20px;
      }
    }
  }
`;