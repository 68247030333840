"use client"

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image } from '../../../styles/home.style'
import { FaTimes } from "react-icons/fa";
import { SidebarData } from "./sidebar/data";
import SubMenu from "./sidebar/index";
import Logo from "../../../assets/Suta Logo.svg";
import { FlexBox, Wrapper, SideLink } from "./style";

export default function SmallSidebar ({ isSidebarOpen, toggleSideBar }) {
	const navigate = useNavigate();
	return (
		<Wrapper>
			<div
				className={
					isSidebarOpen ? "sidebar-container show-sidebar" : "sidebar-container"
				}
			>
				<div className="content">
					<FlexBox>
                        <Image>
                            <Link to="/">
                                <img src={Logo} alt="logo"  onClick={() => navigate("/dashboard")} />
                            </Link>
                        </Image>
						<button type="button" className="close-btn" onClick={toggleSideBar}>
							<FaTimes />
						</button>
					</FlexBox>

					<SideLink>
						<p>Menu</p>
						{SidebarData.map((item, index) => {
						return (
							<SubMenu
								item={item}
								key={index}
								toggleSideBar={toggleSideBar}
							/>
						);
						})}
					</SideLink>
				</div>
			</div>
		</Wrapper>
	);
};