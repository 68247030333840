import React, { useState } from 'react'
import SubNav from '../../../components/sub-nav'
import { Flex, Image, Text, Stack, Button, Container, Spinner } from '@chakra-ui/react'
import { voucher } from '../../../assets/dashboard'
import styled from '@emotion/styled'
import { BsArrowRight } from 'react-icons/bs'
import { colors } from '../../../styles/theme'
import Cookies from 'js-cookie'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { decrement, increment } from '../../../store/reducers/loading'

const Voucher = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const [amount, setAmount] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!amount && !voucher) {
      return toast.error("please provide all values");
    }
    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/wallet/fund`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          type: "voucher",
          amount: amount,
          voucher_code: voucherCode,

        }), // Convert the form data to JSON
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        const msgToPass = "Your wallet funding was successful!"
        navigate("/dashboard/success", { state: { type: 'funded', data: msgToPass, transaction: data.data } });

      }
    } catch (err) {
      dispatch(decrement());
      // Handle errors
      // console.log(err)
      if (err.response && err.response.status === 401) {
        // Redirect to the login page if the API returns a 401 error
        navigate('/auth/login');
      }
    }

    // // console.log(user);
  };

  return (
    <Wrapper>
      <SubNav title='Voucher' />
      <Container
        maxW={{ base: 'sm', lg: 'lg' }}
        my='2rem'
      >
        <Flex
          justify={'center'}
          alignItems='center'
          gap={'1.2rem'}
          fontSize={{ base: '24px', lg: '34px' }}
          fontWeight='400'
          lineHeight={'33px'}
          color='#FB5167'
          my='1rem'
        >
          <Image src={voucher} alt='icon' w={{ base: '40px', lg: '50px' }} />Voucher
        </Flex>

        <form>
          <label>Amount</label>
          <InputContainer>
            <h3>₦</h3>
            <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)} type='text' style={{ color: '#FB5167' }} placeholder={'6,000'} value={amount} onChange={e => setAmount(e.target.value)} />
          </InputContainer>

          <label>Voucher code</label>
          <InputContainer>
            <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)} type={'text'} placeholder='Enter your voucher code' value={voucherCode} onChange={e => setVoucherCode(e.target.value)} />
          </InputContainer>
          <Text textAlign='center' fontSize={'15px'} fontFamily='raleway'>By choosing Voucher code option, you agree to use <br /> Voucher code as funding mode for your transaction</Text>
          <Stack spacing={10} pt={2}>
            {isLoading() ?
              <Button
                loadingText="Loading"
                size="lg"
                fontSize={{ base: '16px', lg: '18px' }}
                bg={'blue.400'}
                color={'white'}


                _hover={{
                  bg: 'blue.500',
                }}>
                <Spinner />
              </Button>

              :

              <Button
                loadingText="Loading"
                size="lg"
                fontSize={{ base: '16px', lg: '18px' }}
                bg={'blue.400'}
                color={'white'}
                onClick={handleSubmit}
                _hover={{
                  bg: 'blue.500',
                }}>
                Load Voucher <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
              </Button>
            }

          </Stack>
        </form>
      </Container>
    </Wrapper>
  )
}

export default Voucher;


const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;

    br {
      display: none;
    }
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    
    border-radius: 12px;
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 0 10px;
    height: 60px;
    margin-bottom: 1rem;

    @media (max-width: 992px) {
      height: 50px;
    }
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    margin-right: .6rem;
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 8px 12px;
    }
  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }
  /* input::placeholder {
    color: #FB5167;
  } */
`;