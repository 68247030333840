import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../features/user/userSlice'
import { useLocation } from 'react-router-dom';

// import assests and styling
import {Container, Form, InputContainer, SubmitBtn, Wrapper} from "../../styles/login.style";
import {Link} from "react-router-dom";
import { Flex, InputGroup, Button, Input, InputRightElement, Spinner } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { lock, user, email, phone } from '../../assets/auth';

const Register = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [refCode, setRefCode] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const extractedRefCode = params.get('ref');
     setRefCode(extractedRefCode || '');
  }, []);



    const { isLoading, success } = useSelector((store) => store.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const urlParams = new URLSearchParams(window.location.search);
    // const referalCode = urlParams.get('ref');
    console.log(refCode);
    const formik = useFormik({
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
        referal_code: refCode,
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        first_name: Yup.string()
          .max(50, "Must be 15 characters or less")
          .required("Required"),
        last_name: Yup.string()
          .max(50, "Must be 15 characters or less")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Email address is required"),
        phone_number: Yup.string()
          .min(10, "Must be at least 10 characters")
          .required("Phone number is required"),
        password: Yup.string()
          .min(6, "Must be at least 6 characters")
          .required("Required"),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Required"),
       
      }),
      onSubmit: async (values, { setSubmitting }) => {
        try {
          dispatch(registerUser(values));
          // setTimeout(() => {
          // 	alert(JSON.stringify(values, null, 2));
          // 	setSubmitting(false);
          // }, 400);
        } catch (error) {
          // console.log(error);
        }
      },
    });

   
    useEffect(() => {
      
      if (success) {
        setTimeout(() => {
          navigate("/auth/login");
        }, 1000);
      }
    }, [success]);

  return (
      <>
        <Container>
            <Wrapper>

                <Flex
                    w={'500px'}
                    justifyContent={'start'}
                    alignItems={'start'}
                    flexDir={'column'}
                    margin={'5px auto'}
                    gap={'5px'}
                    textTransform='capitalize'
                >
                    <h2>Register an Account</h2>
                    <p>Join Suta Community</p>
                </Flex>

                {/* =============== form fields ============ */}
                <Form onSubmit={formik.handleSubmit}>

                  {/* ============= first name input field ===============  */}
                  {formik.touched.first_name && formik.errors.first_name ? (
                    <div className="red-font">{formik.errors.first_name}</div>
                  ) : null}
                  <InputContainer>
                    <img src={user} alt='user-icon' />
                    <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                      type="text"
                      placeholder="First name"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="first_name" // Add name attribute
                      value={formik.values.first_name}
                    />
                  </InputContainer>

                  {/* ============= last name input field ===============  */}
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <div className="red-font">{formik.errors.last_name}</div>
                  ) : null}
                  <InputContainer>
                    <img src={user} alt='user-icon' />
                    <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                      type="text"
                      placeholder="Last name"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="last_name" // Add name attribute
                      value={formik.values.last_name}
                    />
                  </InputContainer>

                  {/* ============= Phone number input field ===============  */}
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="red-font">{formik.errors.phone_number}</div>
                  ) : null}
                  <InputContainer>
                    <img src={phone} alt='phone-icon' />
                    <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                      type="text" // Change to text type (unless it's really meant to be a number)
                      placeholder="Phone number"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="phone_number" // Add name attribute
                      value={formik.values.phone_number}
                    />
                  </InputContainer>
                
                  {/* ============= email address input field ===============  */}
                  {formik.touched.email && formik.errors.email ? (
                    <div className="red-font">{formik.errors.email}</div>
                  ) : null}
                  <InputContainer>
                    <img src={email} alt='email-icon' />
                    <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                      type="email"
                      placeholder="Email"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="email" // Add name attribute
                      value={formik.values.email}
                    />
                  </InputContainer>


                  {/* ================= referal code ============== */}
                  {formik.touched.referal_code && formik.errors.referal_code ? (
                    <div className="red-font">{formik.errors.referal_code}</div>
                  ) : null}
                  <InputContainer>
                    <img src={phone} alt='phone-icon' />
                    <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                      type="text" // Change to text type (unless it's really meant to be a number)
                      placeholder="Referral Code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="referal_code" // Add name attribute
                      value={formik.referal_code}
                      
                    />
                  </InputContainer>

                  {/* =============== create passowrd ============ */}
                  {formik.touched.password && formik.errors.password ? (
                    <div className="red-font">{formik.errors.password}</div>
                  ) : null}
                  <InputContainer>
                    <img src={lock} alt='lock-icon' />
                    <InputGroup p={0} m={0}>
                      <Input 
                        type={showPassword ? 'text' : 'password'} 
                        placeholder="Create password"
                        variant={'unstyled'}
                        required
                        _focus={{
                          border: 'none',
                          outline: 'none',
                          background: 'transparent'
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="password" // Add name attribute
                        value={formik.values.password}
                        />
                      <InputRightElement h={'full'}>
                        <Button
                          variant={'ghost'}
                          _focus={{
                            border: 'none',
                            outline: 'none',
                            background: 'transparent'
                          }}
                          _hover={{
                            background: 'transparent'
                          }}
                          onClick={() => setShowPassword((showPassword) => !showPassword)}>
                          {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </InputContainer>

                  {/* =============== confirm passowrd ============ */}
                    {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                      <div className="red-font">{formik.errors.password_confirmation}</div>
                    ) : null}
                  <InputContainer>
                    <img src={lock} alt='lock-icon' />
                    <InputGroup p={0} m={0}>
                      <Input 
                        type={showConfirmPassword ? 'text' : 'password'} 
                        placeholder="Confirm password"
                        variant={'unstyled'}
                        required
                        _focus={{
                          border: 'none',
                          outline: 'none',
                          background: 'transparent'
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="password_confirmation" // Add name attribute
                        value={formik.values.password_confirmation}
                        />
                      <InputRightElement h={'full'}>
                        <Button
                          variant={'ghost'}
                          _focus={{
                            border: 'none',
                            outline: 'none',
                            background: 'transparent'
                          }}
                          _hover={{
                            background: 'transparent'
                          }}
                          onClick={() => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword)}>
                          {showConfirmPassword ? <ViewIcon /> : <ViewOffIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </InputContainer>

             

                  {isLoading ?
            <div className='icon-wrapper'  >
              <SubmitBtn>
                <Spinner />
              </SubmitBtn>
            </div>
            :
            <SubmitBtn type="submit">
               Register
            </SubmitBtn>
          }
                  <p id="already-user">
                    You have an account? <Link to="/auth/login">Sign in</Link>
                  </p>
                </Form>
            </Wrapper>
        </Container>
      </>
  )
}

export default Register;