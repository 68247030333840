import { Box, Button, Image, Text, Card, Flex } from '@chakra-ui/react'
import React,  { useRef }from 'react'
import { successIcon, sutaIcon } from '../../assets/dashboard'
import { colors } from '../../styles/theme'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CurrencyFormatter } from '../../utils/common';

const SuccessCard = ({ message, transaction }) => {



  const contentRef = useRef(null);

  const handleDownloadPDF = () => {
    if (!contentRef.current) return;

    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('download.pdf');
    });
  };


  const handleDownloadJPEG = () => {
    if (!contentRef.current) return;

    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'download.jpeg';
      link.click();
    });
  };

  const StyledTable = () => {
    return (
      <table style={{ width: '80%', borderCollapse: 'collapse', margin: 'auto'}}>
        <thead >
        <tr>
            <th style={{ backgroundColor: '#f2f2f2', border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Reference</th>
            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{transaction?.transaction_reference}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#f2f2f2', border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Receiver Name</th>
            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{transaction?.receiver?.first_name} {transaction?.receiver?.last_name}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#f2f2f2', border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Receiver Number</th>
            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{transaction?.receiver?.phone_number}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#f2f2f2', border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Receiver Email</th>
            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{transaction?.receiver?.email}</td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#f2f2f2', border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Amount</th>
            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>{CurrencyFormatter(transaction?.amount)}</td>
          </tr>
        </thead>
      </table>
    );
  };

  return (
    <Flex justify={'center'} align='center'>
        <Card 
            w={'550px'} 
            textAlign='center' 
            my={{ base: '2rem', lg: '4rem' }}
            boxShadow={'lg'} 
            borderRadius='lg'
            p='5'
            
            display='flex'
            justify={'center'}
            align='center'
            gap={'1rem'}
            flexDir={'column'}
        >
          <Image src={successIcon} alt='icon' w='200px' />
          <Text fontSize={'24px'} fontWeight='600' fontFamily={'inter'}>Congratulations!</Text>
          <Text fontSize={'16px'} fontWeight='400' fontFamily={'inter'}>{message}</Text>
          <div className='flex' ref={contentRef}>
            <h1 style={{fontWeight: 'bolder', fontSize: '20px'}}>SUTA</h1>
            <StyledTable />
          </div>
            <Button 
            as={'a'}
            href='/dashboard'
            fontFamily={'raleway'} 
            fontSize={{ base: '16px', lg: '18px' }}
            borderRadius='26px'
            height={'50px'}
          
            p='0 2rem'
            bg={`${colors.primaryColor}`}
            color={`${colors.lightColor}`}
            >Go Home</Button>
             
             <Button 
            onClick={handleDownloadJPEG}
            fontFamily={'raleway'} 
            fontSize={{ base: '16px', lg: '18px' }}
            borderRadius='26px'
            height={'50px'}
          
            p='0 2rem'
            bg={`${colors.primaryColor}`}
            color={`${colors.lightColor}`}
            >Download as JPEG</Button>
        </Card>
  
    </Flex>
  )
}

export default SuccessCard