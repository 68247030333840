'use client'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  Heading,
  Link,
  Button
} from '@chakra-ui/react'
import { sutaIcon } from '../../../assets/dashboard'
import { Wrapper } from '../../../components/dashboard-nav/style'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { FaBell } from 'react-icons/fa'
import { colors } from '../../../styles/theme'
import { BsBoxArrowInLeft } from 'react-icons/bs'

const Links = ['Home', 'Transactions', 'My Account']
const url = [ '/dashboard', '/dashboard/transactions', '/dashboard/my-account' ]

const NavLink = ({ children, index }) => {
  return (
    <Box
      as="a"
      px={2}
      py={1}
      color={`${colors.lightColor}`}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.600', 'gray.700'),
      }}
      href={url[index]}>
      {children}
    </Box>
  )
}

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the authentication token
    localStorage.removeItem('token');
    Cookies.remove('Token');
    
    // Redirect to the login page
    navigate("/auth/login"); // Assuming "/login" is the route for the login page
  };

  return (
    <>
      <Wrapper bg={useColorModeValue('blue.100', 'blue.900')} px={8}>
        <Flex h={{ base: 24, lg: 32 }} alignItems={'center'} justifyContent={'space-between'}>
          <Image src={sutaIcon} alt='logo' onClick={() => navigate("/dashboard")} />
          <HStack spacing={8} justifyContent={'space-between'} alignItems={'center'}>
            <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }} color='#fff'>
              {Links.map((link, index) => (
                <NavLink key={index} index={index}>{link}</NavLink>
                ))}
            </HStack>
          </HStack>
          <Flex gap={'10px'} alignItems='center'>
            <Button
            size="md"
            bg={`${colors.lightColor}`}
            color={`${colors.primaryColor}`}
            onClick={handleLogout}
            _hover={{
                bg: `${colors.primaryColor}`,
                color: `${colors.lightColor}`
            }}>
              Log out
            </Button>
            <Link href='/dashboard/notifications' bg={'#fff'} borderRadius='8px' p={'10px 18px'}>
              <FaBell />
            </Link>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
          </Flex>
        </Flex>



        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}

        <Heading fontSize={{ base: '36px', lg: '41px' }} textAlign='center' fontFamily={'inter'} fontWeight='400' color={`${colors.lightColor}`}>Account</Heading>

      </Wrapper>
    </>
  )
}
