import React, { useEffect, useState } from 'react'
import SubNav from '../../../../components/sub-nav';
import { Container, Textarea, Heading, VStack, Text, Button, Badge, Divider, HStack, Box, Table, Thead, Th, Tbody, Td, Tr, Select, Input, Image, FormControl, Spinner } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { CurrencyFormatter, ParseSeconds, ReferenceGenerator } from '../../../../utils/common';
import Pagination from '../../../../components/pagination';
import TaskNav from '../../../../components/tasknav';
import { colors } from '../../../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../../store/reducers/loading';


const TaskDetailsPage = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const { subTaskId } = useParams();
  const { owner } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [subTask, setSubTask] = useState({});
  const [proofs, setProofs] = useState([]);
  const [rejectReason, setRejectReason] = useState([]);
  const maxSizeInBytes = 1024 * 1024;


  const ApprovalStatus = ({ status }) => {
    switch (status) {
      case "APPROVED":
      case "ACTIVE":
        return <Badge colorScheme="green">{status}</Badge>
      case "INACTIVE":
      case "REJECTED":
      case "EXPIRED":
        return <Badge colorScheme="red">{status}</Badge>
      case "PENDING":
      case "IN_PROGRESS":

        return <Badge colorScheme="yellow">{status}</Badge>
      default:
        return <Badge colorScheme="blue">{status}</Badge>

    }

  }


  const checkFileSize = (file) => {
    const fileSize = file.size;

    if (fileSize > maxSizeInBytes) {
      toast({
        title: "File too large",
        status: 'error',
        isClosable: true,
      })
      return false;
    }
    return true;
  }

  const handleReviewSubTask = async (status) => {
    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/reviewSubTask`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          sub_task_id: subTaskId,
          status: status,
          reject_reason: rejectReason
        }),
      });



      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        toast({
          title: "Review Completed",
          status: 'success',
          description: data?.message,
          isClosable: true,
        })
        setSubTask(data.data);
      }
    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const handleAppeal = async () => {
    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/appealSubTask`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          sub_task_id: subTaskId
        }),
      });



      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        toast({
          title: "Review Completed",
          status: 'success',
          description: data?.message,
          isClosable: true,
        })


        setSubTask(data.data);
      }
    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const handleSubmitProof = async () => {

    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/submitProofs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          sub_task_id: subTaskId,
          proofs: proofs
        }),
      });



      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        setSubTask(data.data);
        setProofs([]);
      }
    } catch (err) {
      dispatch(decrement());
      // Handle errors
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const getSingleTask = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/${subTaskId}/getSingleSubTask`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });



      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setSubTask(data.data)
    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }
  useEffect(() => {
    getSingleTask();
  }, []);

  function fileToBase64(file, index, callback) {
    if (checkFileSize(file)) {
      const reader = new FileReader();
      reader.onload = function (event) {
        callback(event.target.result, index);
      };
      reader.readAsDataURL(file);
    }
  }



  const handleItemChange = (value, index) => {
    // console.log(value);
    setProofs((prevProofs) => {
      const newProofs = [...prevProofs];
      newProofs[index].item = value;
      return newProofs;
    });
  }

  const handleTypeChange = (e, index) => {
    setProofs((prevProofs) => {
      const newProofs = [...prevProofs];
      newProofs[index].type = e.target.value;
      return newProofs;
    });
  }


  return (
    <div>
      <Wrapper>
        <SubNav title={'Job Details'} />
        <TaskNav />
        <Box p={4} borderWidth="1px" borderRadius="lg">

          <Heading mb={4}>#{ReferenceGenerator(subTask?.id, subTask?.created_at)}</Heading>

          <Box p={4} borderWidth="1px" borderRadius="lg">
            <VStack spacing={4} align="start">
              <Text fontSize="lg"><b>Task Name:</b> {subTask?.task?.name}</Text>
              <Text fontSize="md"><b>Description:</b> 
            
                  <Box
                    p={4}
                    border=""
                    borderRadius="md"
                    width="100%"
                    whiteSpace="pre-wrap"
                    dangerouslySetInnerHTML={{ __html: subTask?.task?.description }}
                  />
               
              </Text>
              <Text fontSize="md"><b>Execution Time:</b> {ParseSeconds(subTask?.task?.execution_time_in_seconds)}</Text>
              <Text fontSize="md"><b>Job Amount:</b> {CurrencyFormatter(subTask?.amount)}</Text>
              <Text fontSize="md"><b>Task Status:</b> <ApprovalStatus status={subTask?.task_status} /></Text>
              <Text fontSize="md"><b>Settlement:</b> <ApprovalStatus status={subTask?.setlement_id ? "SETTLED" : "NOT SETTLED"} /> </Text>
              {subTask?.task_status == "REJECTED" && <Text fontSize="lg"><b>Reject Reason:</b> {subTask?.reject_reason}</Text>}
            </VStack>
          </Box>

          <Divider my={6} />


        </Box>
        <Box p={4} borderWidth="1px" borderRadius="lg">
          <Heading size={'sm'} mb={4}>Proof Of Work</Heading>
          <Box>
            <Table>
              <Tbody>
                {subTask?.sub_task_proofs?.map((proof, index) => (
                  <Tr key={index}>
                    <Td>#{index + 1}</Td>
                    <Td>
                      {proof.type == 'image' ?
                        <Image
                          height={'200px'}
                          // objectFit="cover" 
                          src={`${process.env.REACT_APP_BACKEND_URL}/images/proofs/${proof.item}`}
                          alt="Image failed to load"
                        /> :

                        proof.item

                      }
                    </Td>
                  </Tr>
                ))}

                {proofs.map((proof, index) => (
                  <Tr key={index}>
                    <Th>
                      
                      <Select value={proof.type} onChange={(e) => handleTypeChange(e, index)}>
                        <option value={'image'}>Image</option>
                        <option value={'text'}>Text</option>
                      </Select>
                      

                    </Th>
                    <Td>
                      {proof.type == 'text' ?
                        <Textarea value={proof.item} onChange={(e) => handleItemChange(e.target.value, index)} placeholder="Type something..." />
                        :
                        <>
                          <Input type="file" onChange={(e) => fileToBase64(e.target.files[0], index, handleItemChange)} />
                          <small class="text-danger">less than 1mb</small>
                        </>

                      }

                    </Td>
                    <Td>
                      <Button onClick={() => setProofs(proofs.filter((_, indexF) => indexF !== index))} size="sm" colorScheme="red">Delete</Button>
                    </Td>
                  </Tr>
                ))}
                {subTask?.sub_task_proofs?.length < 1 && <Tr>No proof submitted</Tr>}
              </Tbody>
            </Table>
          </Box>
          {owner != "true" ?
            <HStack spacing={4}>
              {subTask.status == "REJECTED" && <Button onClick={handleAppeal} size="lg" colorScheme="blue">Appeal</Button>}
              <Button onClick={() => setProofs([...proofs, { type: "text", item: "" }])} size="lg" colorScheme="green">+</Button>
              {proofs.length > 0
                && <>
                  {isLoading() ?
                    <Button size="lg" colorScheme="blue"><Spinner /></Button>
                    :
                    <Button onClick={handleSubmitProof} size="lg" colorScheme="blue">Upload</Button>
                  }
                </>

              }
            </HStack>
            :
            <>
              {subTask?.task_status == "SUBMITTED" &&
                <Box p={4} borderWidth="1px" borderRadius="lg">
                  <FormControl margin={'10px'}>
                    <label>Enter Reason to Reject</label>
                    <Textarea value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} placeholder="Enter a reason to reject" />
                  </FormControl>
                  <HStack>
                    {isLoading() ?
                      <Button size="md" margin={'5px'} colorScheme="green"><Spinner /></Button>
                      :
                      <Button onClick={() => handleReviewSubTask('COMPLETED')} size="md" margin={'5px'} colorScheme="green">APPROVE</Button>

                    }

                    {rejectReason.length > 0 && <>
                      {isLoading() ?
                        <Button size="md" colorScheme="red"><Spinner /></Button>
                        :
                        <Button onClick={() => handleReviewSubTask('REJECTED')} size="md" colorScheme="red">REJECT</Button>

                      }
                    </>
                    }

                  </HStack>

                </Box>}
            </>
          }
        </Box>


      </Wrapper>
    </div>
  )
}

export default TaskDetailsPage

const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    height: 60px;
    
    border-radius: 12px;
    border: 1px solid ${colors.textColor};
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 8px 10px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    width: 55px;
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }

  img {
    align-self: center;
    margin: 0 1rem;
  }
`;




