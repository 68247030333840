import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Market from '../pages/dasboard/tasks/market'
import Tasks from '../pages/dasboard/tasks/tasks'
import Jobs from '../pages/dasboard/tasks/jobs'
import Settlements from '../pages/dasboard/tasks/settlements'
import TaskDetailsPublic from '../pages/dasboard/tasks/market/details'
import SubTaskDetails from '../pages/dasboard/tasks/jobs/details'
import CreateTask from '../pages/dasboard/tasks/tasks/create'
import TaskDetails from '../pages/dasboard/tasks/tasks/details'
import EditTask from '../pages/dasboard/tasks/tasks/edit'




function TaskRoutes() {
  return (
    <Routes>
        <Route path='/market' element={<Market />} />
        <Route path='/tasks/create' element={<CreateTask />} />
        <Route path='/tasks' element={<Tasks />} />
        <Route path='/jobs' element={<Jobs />} />
        <Route path='/jobs/:settlementId' element={<Jobs />} />
        <Route path='/settlements' element={<Settlements />} />
        <Route path='/market/:taskId/details' element={<TaskDetailsPublic />} />
        <Route path='/tasks/:taskId/details' element={<TaskDetails />} />
        <Route path='/tasks/:taskId/edit' element={<EditTask />} />
        <Route path='/jobs/:subTaskId/details' element={<SubTaskDetails />} />
        <Route path='/jobs/:subTaskId/details/:owner' element={<SubTaskDetails />} />

        
    </Routes>
  )
}

export default TaskRoutes