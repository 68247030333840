import React from 'react'
import { Container, Heading, Link, Text } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { colors } from '../styles/theme';

export default function AboutUs() {
  return (
    <div>
        <Container maxW={{ base: 'sm', md: 'md', lg: 'container.sm' }} my='2rem' textAlign={'start'} fontFamily='raleway'>
            <div>
                {/* ======= about ============= */}
                <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>About Suta - Simplifying Bill Payments</Heading>
                <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>At Suta, we believe that paying bills should be a hassle-free experience, and that's why we're dedicated to simplifying the bill payment process for everyone. Our platform, located at <Link href='/' color={`${colors.primaryColor}`} fontWeight='500'>www.suta.ng</Link>, is designed with your convenience in mind, offering a seamless and secure solution for all your bill payment needs.</Text>

                {/* ======= our mission ============= */}
                <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>Our Mission</Heading>
                <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>Our mission is clear: to empower individuals and businesses by providing a user-friendly, reliable, and efficient bill payment platform. We understand that managing bills can be time-consuming and stressful, which is why we're committed to making the process as smooth as possible. We want to give you more time to focus on the things that matter most to you.</Text>


                {/* ======= why choose us ============= */}
                <Heading fontSize={'25px'} fontWeight='700' mb={'1rem'}>Why Choose Suta?</Heading>
                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>1. Convenience:</Span> We bring the power of bill payment to your fingertips. With Suta, you can pay your bills from the comfort of your home, office, or anywhere you have an internet connection. Say goodbye to long queues and paper bills.</Text>

                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>2. Variety of Billers:</Span> We've partnered with a wide range of billers, from utility companies to service providers, to ensure that you can pay all your bills in one place. Whether it's electricity, water, internet, or mobile services, Suta has you covered.</Text>

                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>3. Security:</Span> We take the security of your personal and financial information seriously. Our platform employs advanced security measures to safeguard your data and transactions. Your privacy and trust are our top priorities.</Text>

                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>4. User-Friendly Interface:</Span> Our platform is designed to be intuitive and easy to navigate. You don't need to be a tech expert to use Suta. Our user-friendly interface ensures that you can complete your bill payments with just a few clicks.</Text>

                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>5. 24/7 Accessibility:</Span> Bills don't adhere to a 9-to-5 schedule, and neither do we. Suta is available 24/7, so you can make payments whenever it's convenient for you, whether it's early in the morning or late at night.</Text>

                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>6. Transparency:</Span> We believe in transparency in all our transactions. You'll receive detailed receipts and confirmation of your payments, giving you peace of mind.</Text>

                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>7. Customer Support:</Span> If you ever have questions or encounter any issues, our dedicated customer support team is here to assist you. We strive to provide the best support to ensure your experience with Suta is exceptional.</Text>


                {/* ======= community ============= */}
                <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>Join the Suta Community</Heading>
                <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>We invite you to join the growing community of satisfied Suta users who have discovered the convenience and efficiency of our bill payment platform. Whether you're an individual looking to simplify your monthly bills or a business seeking an easier way to manage payments, Suta is here to serve you.</Text>
                <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>Experience the future of bill payments with Suta. Say goodbye to the hassle and stress, and embrace the simplicity and convenience. Your bills, our solution – that's the Suta promise.</Text>


                {/* ======= contact us ============= */}
                <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>Contact Us</Heading>
                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'>Have questions, suggestions, or feedback? We'd love to hear from you. Contact us at support@suta.ng, and our team will be happy to assist you.</Text>
                <Text fontSize={'16px'} fontWeight='400' mb='.8rem'>Thank you for choosing Suta for your bill payment needs. We look forward to serving you and making your bill payment experience effortless.</Text>
            </div>
        </Container>
    </div>
  )
}


const Span = styled.span`
    color: #000;
    font-weight: 700;
`;