import styled from "@emotion/styled";
import { colors } from "./theme";

export const Utils = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-content: center;
  gap: 2rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .box{
    background: #fff;
    border-radius: 8px;
    width: 150px;
    
    padding: 1.5rem 2.5rem;
    /* margin-bottom: 1rem; */

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 992px) {
      padding: 1rem 2rem;
    }

    img {
      width: 60px;
      height: 60px;
    }
    p {
      color: ${colors.textColor};
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
  }

  

  @media (max-width: 992px) {
    gap: 30px;
  }
`;