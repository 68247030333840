import React from "react";
import { Image } from '../../styles/home.style'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// styling
import { 
    Flex,
    Box,
    IconButton,
    Stack,
    HStack,  
    Link, 
    useDisclosure,
    useColorModeValue,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
  } from '@chakra-ui/react'
import { logoutUser } from "../../features/user/userSlice";
import { colors } from "../../styles/theme";
import styled from "styled-components";

// assets 
import Logo from "../../assets/Suta Logo.svg";
import {BsFacebook, BsInstagram, BsPhone, BsWhatsapp} from "react-icons/bs";
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import {FaUserCircle} from "react-icons/fa";
import { FiTwitter, FiSettings } from "react-icons/fi";
import {TbMail} from "react-icons/tb";

//component import
// import MobileNav from "./mobilenav";

const Links = ['Home', 'About us', 'Privacy Policy'];
const url = [ '/', '/about-us', '/privacy-policy']

const NavLink = ({ children, index }) => {
    return (
      <Box
        as="a"
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        href={url[index]}>
        {children}
      </Box>
    )
  }

const Navbar = ({ toggleSideBar }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user } = useSelector((store) => store.user);
    const logOut = () => {
		dispatch(logoutUser());
		navigate("/");
	};

    return (
        <>
            <Box>
                <NavWrapper>
                    <Flex
                        gap={{ base: '15px', md: '20px', lg: '25px'}}
                        justify={'start'}
                        align={'center'}
                    >
                        <BsInstagram />
                        <BsFacebook />
                        <FiTwitter />
                    </Flex>

                    <NavLinks>
                        <Link href="https://wa.me/+2349036898349">
                            <BsWhatsapp />
                            09036898349
                        </Link>
                        <Link href="tel:09036898349">
                            <BsPhone />
                            09036898349
                        </Link>
                        <Link href="mailto:support@suta.ng">
                            <TbMail />
                            support@suta.ng
                        </Link>
                    </NavLinks>
                </NavWrapper>
                <Flex h={12} alignItems={'center'} justifyContent={'space-between'} bg={'#fff'} px='20px'>
                    <Image>
                            <Link to="/">
                                <img src={Logo} alt="logo" onClick={() => navigate("/dashboard")}  />
                            </Link>
                    </Image>
                    <HStack spacing={8} justifyContent={'space-between'} alignItems={'center'}>
                        <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                        {Links.map((link, index) => (
                            <NavLink key={index} index={index}>{link}</NavLink>
                            ))}
                        </HStack>
                    </HStack>
                    <IconWrapper>
                        {user ? (
                            <Flex alignItems={"center"} gap={"1rem"}>
                                <Button onClick={logOut} color="white" bg={"#C6002D"}>
                                    Logout
                                </Button>
                                <Link to="/dashboard">
                                    <FiSettings size={"1.6rem"} color="#C6002D" />
                                </Link>
                            </Flex>
                        ) : (
                            <Flex display={{ base: 'none', md: 'flex', lg: 'flex' }} gap='10px' alignItems='center' >
                                <FaUserCircle />
                                <Breadcrumb>
                                    <BreadcrumbItem>
                                        <BreadcrumbLink href='/auth/login'>Login</BreadcrumbLink>
                                    </BreadcrumbItem>

                                    <BreadcrumbItem>
                                        <BreadcrumbLink href='/auth/register'>Register</BreadcrumbLink>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Flex>
                        )}


                        <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                        />
                    </IconWrapper>
                </Flex>

                {isOpen ? (
                <Box pb={4} display={{ md: 'none' }} pl='20px'>
                    <Stack as={'nav'} spacing={4}>
                        {Links.map((link, index) => (
                            <NavLink key={index} index={index}>{link}</NavLink>
                        ))}
                    </Stack>
                    <Flex
                        gap={'20px'}
                        justify={'start'}
                        align={'center'}
                        px='10px'
                        mt='15px'
                    >
                        <BsInstagram style={{ cursor: 'pointer'}} />
                        <BsFacebook style={{ cursor: 'pointer'}} />
                        <FiTwitter style={{ cursor: 'pointer'}} />
                    </Flex>
                    {user ? (
                            <Flex alignItems={"center"} gap={"1rem"}>
                                <Button onClick={logOut} color="white" bg={"#fff"}>
                                    Logout
                                </Button>
                                <Link to="/dashboard">
                                    <FiSettings size={"1.6rem"} color="#fff" />
                                </Link>
                            </Flex>
                        ) : (
                            <Flex mt='20px' px='10px' gap={'10px'} alignItems='center'>
                                <Button href="/auth/login">Login</Button>
                                <Button href="/auth/register">Register</Button>
                            </Flex> 
                        )
                    }
                </Box>
                ) : null}
            </Box>
        </>
    )
}

export default Navbar;

const Wrapper = styled.div`
    width: 100%;
    /* padding: 1.4rem 3rem; */

    @media (max-width: 992px) {
        display: none !important;
    }   
`;

const Container = styled.div`

    @media (max-width: 992px) {
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .mobile-bottom {
        display: none;

        @media (max-width: 992px) {
            /* margin-top: -180px; */
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
        }
    }
`;

export const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 10px 25px;
  color: #f4f4f4;
  
  background: ${colors.primaryColor};
  width: 100%;
  height: 7vh;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  gap: 15px;
  
  a {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

const Button = styled.a`
    font-size: 16px;
    color: ${colors.lightColor};
    background: ${colors.primaryColor};
    padding: 8px 20px;
    border-radius: 6px;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    a {

    }
`;