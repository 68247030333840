
import { Badge } from "@chakra-ui/react";


export const CurrencyFormatter = ( amount ) => {
    // Format the number as currency
   return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NGN', // Change to your desired currency code
      minimumFractionDigits: 2,
      currencyDisplay: 'narrowSymbol'
    }).format(amount);
  
    
  };

  export const  ParseSeconds = (seconds) => {
    const minute = 60;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    const month = 30 * day; // Approximate value for a month
  
    if (seconds < minute) {
      return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    } else if (seconds < hour) {
      const minutes = Math.floor(seconds / minute);
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else if (seconds < day) {
      const hours = Math.floor(seconds / hour);
      return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else if (seconds < week) {
      const days = Math.floor(seconds / day);
      return `${days} day${days !== 1 ? 's' : ''}`;
    } else if (seconds < month) {
      const weeks = Math.floor(seconds / week);
      return `${weeks} week${weeks !== 1 ? 's' : ''}`;
    } else {
      const months = Math.floor(seconds / month);
      return `${months} month${months !== 1 ? 's' : ''}`;
    }
  }

  export const ApprovalStatus = ({status}) => {
    switch(status) {
        case "APPROVED":
        case "ACTIVE" :
            return <Badge colorScheme="green">{status}</Badge>
        case "INACTIVE":
        case "REJECTED":
            return <Badge colorScheme="red">{status}</Badge>
        case "PENDING":
        case "IN_PROGRESS":
            return <Badge colorScheme="yellow">{status}</Badge>
        default: 
        return <Badge colorScheme="blue">{status}</Badge>
            
    }
 
  }

  export const ReferenceGenerator = (id, createdAtISOString) => {
  
    return `ref_${id}_${formatDate(createdAtISOString)}`;
      
  }


  function formatDate(inputDateString) {
    const date = new Date(inputDateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}${month}${day}`;
  }


  export const parseDate = (date) => {
    const createdAtDate = new Date(date);
    const fullYear = createdAtDate.getFullYear();
    const months = createdAtDate.getMonth() + 1;
    const days = createdAtDate.getDate();
    const time = createdAtDate.toLocaleTimeString();

    return `${fullYear}-${months}-${days} ${time}`;

  } 

