import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

// import assests and styling
import { Container, Wrapper, InputContainer, SubmitBtn, Form } from '../../styles/login.style'
import { Flex, Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { phone } from '../../assets/auth';
import { decrement, increment } from '../../store/reducers/loading';
import { useDispatch, useSelector } from 'react-redux';

const Forget = () => {
  const [phone_number, setPhone_number] = useState("");
  // const { user, isLoading } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingCount = useSelector((state) => state.loading.count);
  const isLoading = () => {


    return loadingCount > 0;
  }
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  const handleNumberChange = (event) => {
    setPhone_number(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!phone_number) {
      return toast.error("please provide all values");
    }

    try {
      dispatch(increment());
      const response = await fetch(`${baseUrl}/api/v1/reset_password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone_number: phone_number,
        }), // Convert the form data to JSON
      });

      const data = await response.json();

      Cookies.set('phone_number', phone_number, { expires: 1 });
      if (data.status) {
        navigate("/auth/otp");
      }

      dispatch(decrement());
    } catch (err) {
      // Handle errors
      dispatch(decrement());
      if (err.response && err.response.status === 401) {
        // Redirect to the login page if the API returns a 401 error
        navigate('/auth/login');
      }
    }


  };

  return (
    <Container>
      <Wrapper>

        <Flex
          w={'500px'}
          justifyContent={'start'}
          alignItems={'start'}
          flexDir={'column'}
          margin={'5px auto'}
          gap={'5px'}
          textTransform='capitalize'
        >
          <h2>Forgot Password</h2>
          <p>Enter your Phone number and get OTP to verification!</p>
        </Flex>

        <Form onSubmit={handleSubmit}>
          <InputContainer>
            <img src={phone} alt='phone-number' />
            <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
              type="text"
              placeholder="Phone number"
              value={phone_number}
              onChange={handleNumberChange}
              required
            />
          </InputContainer>

          {isLoading() ?
            <div className='icon-wrapper'  >
              <SubmitBtn>
                <Spinner />
              </SubmitBtn>
            </div>
            :

            <SubmitBtn type="submit">
              Recover Account
            </SubmitBtn>

          }

          <p id="already-user">
            Remeber Password? <Link to="/auth/login">Sign In</Link>
          </p>
        </Form>
      </Wrapper>
    </Container>
  )
}

export default Forget