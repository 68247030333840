import React from 'react'
import { Container, Heading, Text, Link } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { colors } from '../styles/theme';

export default function PrivacyPolicy() {
  return (
    <div>
        <Container maxW={{ base: 'sm', md: 'md', lg: 'container.sm' }} my='2rem' textAlign={'start'} fontFamily='raleway'>
            <Heading fontSize={'25px'} fontWeight='700' mb={'1rem'}>Privacy Policy</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'>Effective Date: 21/09/2023</Text>

            {/* ======= about ============= */}
            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>Introduction</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>Welcome to Suta, a convenient and secure bill payment platform (the "Platform") provided by [Company Name], hereinafter referred to as "we," "our," or "us." Protecting your privacy is a priority for us, and we are committed to safeguarding the personal information you entrust to us. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your information when you use our Platform.</Text>

            <Heading fontSize={'25px'} fontWeight='700' mb={'1rem'}>1. Information We Collect</Heading>
            <Heading fontSize={'25px'} fontWeight='700' mb={'1rem'}>1.1. Information You Provide</Heading>

            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'>When you use the Suta Platform, we may collect the following types of information from you:</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Personal Information:</Span>  This includes information such as your name, email address, phone number, and payment information that you provide when you register for an account or make a payment.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Payment Information: </Span>  We collect payment information such as your credit card details or other financial information when you make a payment on our Platform, through a secured payment gateways like PayStack or Flutterwave and you payment informations are not saved on our or any of the platforms mentioned.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Bill Information:</Span>  We may collect information related to the bills you wish to pay, including the biller's name, account number, and payment amount.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- User Content: </Span>  Any information, messages, comments, or other content you post or share on the Platform, such as feedback or customer support inquiries.</Text>


            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>1.2. Information We Automatically Collect</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>When you use our Platform, certain information is automatically collected, which may include:</Text>

            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Device Information:  </Span>  We collect information about the device you use to access our Platform, including your IP address, device type, and browser information.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Usage Information: </Span>We may collect information about how you use our Platform, including the pages you visit, your interactions with the Platform, and the dates and times of your visits.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Location Information:  </Span>  With your consent, we may collect location information to provide you with relevant services, such as finding nearby billers.</Text>



            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>2. How We Use Your Information</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>We use the information we collect for various purposes, including:</Text>

            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Providing Services: </Span>To process bill payments, communicate with you about your payments, and provide customer support.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Improving Our Services: </Span>To analyze user behavior and preferences, diagnose technical issues, and enhance the functionality and usability of our Platform.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Marketing and Communication: </Span>To send you promotional and marketing materials, updates, and newsletters related to our services. You can opt out of these communications at any time.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Compliance:</Span> To comply with legal obligations, including tax and regulatory requirements.</Text>

            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>3. How We Share Your Information</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>We may share your information with third parties in the following circumstances:</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Service Providers: </Span> We may share your information with third-party service providers who assist us in operating our Platform and providing services to you.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Billers: </Span>We share your bill payment information with the relevant billers to process your payments.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Legal Compliance: </Span>We may disclose your information to comply with legal obligations, respond to legal requests, or protect our rights, privacy, safety, or property.</Text>


            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>4. Your Choices</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>You have several rights and choices regarding your information:</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Account Information: </Span>You can review and update your account information at any time through the Platform.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Marketing Communications: </Span>You can opt out of receiving promotional communications from us by following the instructions provided in the communication or contacting us.</Text>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'><Span>- Location Information: </Span>You can control your device's location settings to prevent us from collecting your location information.</Text>


            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>5. Security</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>We take reasonable measures to protect your information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</Text>

            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>6. Children's Privacy</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>Our Platform is not intended for children under the age of 16. We do not knowingly collect or solicit personal information from minors. If you believe a child has provided us with their personal information, please contact us, and we will take steps to remove such information.</Text>

            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>7. Changes to this Privacy Policy</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the updated Privacy Policy on our Platform. Your continued use of the Platform after such changes signifies your acceptance of the updated Privacy Policy.</Text>

            <Heading fontSize={'25px'} fontWeight='700' mb='1rem'>8. Contact Us</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>If you have any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at <Link href='mailto:support@suta.ng' color={`${colors.primaryColor}`} fontWeight='500'>support@suta.ng</Link></Text>
            <Text fontSize={'16px'} fontWeight='400' mb='1.5rem'>Thank you for choosing Suta for your bill payment needs. We are committed to protecting your privacy and providing you with a secure and convenient experience.</Text>
        </Container>
    </div>
  )
}


const Span = styled.span`
    color: #000;
    font-weight: 700;
`;