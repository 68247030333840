import React from 'react'
import { useEffect, useState } from 'react';
import { fund, userImg } from '../../assets/dashboard'
import { MdRefresh } from 'react-icons/md'
import { VscCopy } from 'react-icons/vsc'
import { Box, Flex, Image, Spinner } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { colors } from '../../styles/theme'
import { styled } from 'styled-components'
import copy from 'clipboard-copy';
import Cookies from 'js-cookie';
import { CurrencyFormatter } from '../../utils/common';
import { decrement, increment } from '../../store/reducers/loading';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

const UserDetails = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const toast = useToast()
  const isLoading = () => {
    return loadingCount > 0;
  }
  const navigate = useNavigate();
  const [acctName, setAcctName] = useState('')
  const [bankName, setBankName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [currency, setCurrency] = useState('')
  const [walletBalance, setWalletBalance] = useState('')
  const [profileImg, setProfileImg] = useState(null)
  const [showBalance, setShowBalance] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [roles, setRoles] = useState([]);

  const linkToCopy = `${accountNumber}`;

  const handleGetData = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setAcctName(data.data.account.account_name);
      setBankName(data.data.account.bank_name);
      setAccountNumber(data.data.account.account_number);
      setCurrency(data.data.account.currency_code)
      setWalletBalance(data.data.wallet.balance)
      setProfileImg(data.data.user.profile_image)
      setFirstName(data.data.user.first_name)
      setRoles(data?.data?.user?.roles.map(x => x.name))
   

    } catch (err) {
      dispatch(decrement());
      // Handle errors
      
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }
  useEffect(() => {
    handleGetData();
  }, []);

  const handleCopyClick = async () => {
    try {
      await copy(linkToCopy);
      setLinkCopied(true);

      // Reset isCopied to false after 3 seconds
      setTimeout(() => {
        setLinkCopied(false);
      }, 3000);
    } catch (error) {
      console.error('Copy to clipboard failed: ', error);
    }
  };


  return (
    <div>
      <Flex flexDir={{ base: 'column-reverse', lg: 'row' }} gap='3rem' alignItems={'center'} justifyContent='center'>
        <Box mb={{ base: '1rem' }}>
          <Flex gap={'1rem'} alignItems='center'>
            <h3 className='amount'>{showBalance ? `${CurrencyFormatter(walletBalance)}` : '*******'}</h3>
            <div className='icon-wrapper' onClick={() => setShowBalance((showBalance) => !showBalance)}>
              {showBalance ? <ViewIcon /> : <ViewOffIcon />}
            </div>
            {isLoading() ?
              <div className='icon-wrapper'  >
                <Spinner />
              </div>
              :
              <div className='icon-wrapper' onClick={handleGetData} >
                <MdRefresh />
              </div>
            }
          </Flex>
          <p className='wallet'>Wallet Balance</p>
        </Box>

        <div className='divider' />

        <Flex justify={'center'} alignItems={'center'} gap='1rem'>
          <Image src={profileImg == null ? userImg : profileImg} alt='user-image' w={'45px'} h={'45px'} borderRadius='50%' />
          <Box>
            <h3 className='welcome'>Welcome, {firstName} 👋</h3>
            <p className='question'>What will you be doing today?</p>
            <p className='question'>Designation: {roles?.join(" , ")}</p>
          </Box>
          <a href='/dashboard/fund-wallet' className='fund-wallet'>
            <img src={fund} alt='fund-icon' />
            Fund Wallet
          </a>
        </Flex>
      </Flex>

      <Flex
        borderRadius='18px'
        background={`${colors.darkColor}`}
        backdropFilter='blur(15px)'
        w={{ base: '100%' }}
        h={{ base: '42px', lg: '36px' }}
        mt={{ base: '2rem' }}
        justify={'center'}
        align='center'
      >
        <Account>
          <p className='virtual'>{acctName}</p>
          <p className='number'>{linkToCopy}</p>
          <span className='bank' onClick={handleCopyClick}>{bankName} <VscCopy /></span>
          {linkCopied ? 'Copied!' : ''}
        </Account>
      </Flex>
    </div>
  )
}

export default UserDetails

const Account = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${colors.lightColor};
  border-radius: 14px;
  padding: 15px 25px;

  @media(max-width: 992px) {
    width: 70%;
    flex-direction: column;
    gap: 5px;
  }

  .virtual {
      color: ${colors.darkColor};
      font-family: Raleway;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px; /* 200% */
      letter-spacing: 0.492px;

      @media(max-width: 992px) {
        line-height: 18px;
        text-align: center;
      }
  }
  .number {
      color: ${colors.darkColor};
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 29px; /* 145% */
      letter-spacing: 4.1px;

      @media(max-width: 992px) {
        font-size: 18px;
        line-height: 22px;
      }
  }
  .bank {
      color: ${colors.darkColor};
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 200% */
      letter-spacing: 0.492px;

      cursor: pointer;

      display: flex;
      align-items: center;
      gap: .6rem;

      svg {
        font-size: 18px;
      }

      @media(max-width: 992px) {
        line-height: 18px;
      }
  }
`;