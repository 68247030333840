import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import { createStandaloneToast, InputGroup, Button, Input, InputRightElement } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from 'js-cookie';

// import assests and styling
import {Container, Form, Help, InputContainer, SubmitBtn, Wrapper} from "../../styles/login.style";
import {Link} from "react-router-dom";
import { Flex } from '@chakra-ui/react'
import { lock } from '../../assets/auth';

export const ChangePassword = () => {
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { toast } = createStandaloneToast();
	const { Uid, Token } = useParams();
	const baseUrl = process.env.REACT_APP_BACKEND_URL;

    const formik = useFormik({
      initialValues: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      validationSchema: Yup.object({
        old_password: Yup.string()
          .required("Required"),
        password: Yup.string()
          .min(6, "Must be at least 6 characters")
          .required("Required"),
        password_confirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Required"),
      }),
      onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const resp = await axios.post(
                    `${baseUrl}/api/v1/me/change_password`,
                    {
                        old_password: values.old_password,
                        password: values.password,
                        password_confirmation: values.password_confirmation,
                    }
                );
                toast({
                    title: "Password Changed",
                    description: "Your Password has been changed successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "top",
                    variant: "left-accent",
                });
                setSubmitting(false);
                resetForm();
                Cookies.remove('phone_number');
                Cookies.remove('OTP');
                setTimeout(() => {
                    navigate("/auth/login");
                }, 1000);
            } catch (error) {
             
                setSubmitting(false);
                toast({
                    title: "An error occurred",
                    description: error.response?.data?.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top",
                    variant: "left-accent",
                });
                if (error.response && error.response.status === 401) {
                    // Redirect to the login page if the API returns a 401 error
                   navigate("/auth/login");
                  }
            }
            setSubmitting(false);
        },
    });

  return (
    <div>
        <Container>
            <Wrapper>

                <Flex
                    w={'500px'}
                    justifyContent={'start'}
                    alignItems={'start'}
                    flexDir={'column'}
                    margin={'5px auto'}
                    gap={'5px'}
                    textTransform='capitalize'
                >
                    <h2>Change Your Password</h2>
                    <p>Please create a new password</p>
                </Flex>

                <Form onSubmit={formik.handleSubmit}>

                    {/* ============== Old Password ================ */}
                    {formik.touched.last_name && formik.errors.password ? (
                        <div className="red-font">{formik.errors.password}</div>
                    ) : null}
                    <InputContainer>
                        <img src={lock} alt='lock-icon' />
                        
                        <InputGroup p={0} m={0}>
                        <Input 
                            type={showPassword ? 'text' : 'password'} 
                            placeholder="Old Password"
                            variant={'unstyled'}
                            required
                            _focus={{
                                border: 'none',
                                outline: 'none',
                                background: 'transparent'
                            }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="password_confirmation" // Add name attribute
                            value={formik.values.old_password}
                        />
                        <InputRightElement h={'full'}>
                            <Button
                            variant={'ghost'}
                            _focus={{
                                border: 'none',
                                outline: 'none',
                                background: 'transparent'
                            }}
                            onClick={() => setShowPassword((showPassword) => !showPassword)}>
                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                            </InputRightElement>
                        </InputGroup>
                    </InputContainer>

                    {/* ============== New Password ================ */}
                    {formik.touched.last_name && formik.errors.password ? (
                        <div className="red-font">{formik.errors.password}</div>
                    ) : null}
                    <InputContainer>
                        <img src={lock} alt='lock-icon' />
                        
                        <InputGroup p={0} m={0}>
                        <Input 
                            type={showPassword ? 'text' : 'password'} 
                            placeholder="New Password"
                            variant={'unstyled'}
                            required
                            _focus={{
                                border: 'none',
                                outline: 'none',
                                background: 'transparent'
                            }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="password_confirmation" // Add name attribute
                            value={formik.values.password}
                        />
                        <InputRightElement h={'full'}>
                            <Button
                            variant={'ghost'}
                            _focus={{
                                border: 'none',
                                outline: 'none',
                                background: 'transparent'
                            }}
                            onClick={() => setShowPassword((showPassword) => !showPassword)}>
                            {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                            </InputRightElement>
                        </InputGroup>
                    </InputContainer>

                    {/* ============== Confirm Password  =============  */}
                    {formik.touched.last_name && formik.errors.password_confirmation ? (
                        <div className="red-font">{formik.errors.password_confirmation}</div>
                    ) : null}
                    <InputContainer>
                        <img src={lock} alt='lock-icon' />
                     
                        <InputGroup p={0} m={0}>
                            <Input 
                                type={showPassword ? 'text' : 'password'} 
                                placeholder="Confirm Password"
                                variant={'unstyled'}
                                _focus={{
                                    border: 'none',
                                    outline: 'none',
                                    background: 'transparent'
                                }}
                                required
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="password_confirmation" // Add name attribute
                                value={formik.values.password_confirmation}
                                />
                            <InputRightElement h={'full'}>
                                <Button
                                variant={'ghost'}
                                _focus={{
                                    border: 'none',
                                    outline: 'none',
                                    background: 'transparent'
                                }}
                                onClick={() => setShowPassword((showPassword) => !showPassword)}>
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </InputContainer>

                    <SubmitBtn type="submit">Submit</SubmitBtn>

                    <p id="already-user">You have an account? <Link to="/auth/login">Sign in</Link></p>
                </Form>
            </Wrapper>
        </Container>
    </div>
  )
}
