import React from 'react'
import { Button, Container, Heading, Image, Text } from '@chakra-ui/react';
import Coming from "../assets/coming-soon.jpg";
import { colors } from '../styles/theme';

export default function ComingSoon() {
  return (
    <div>
        <Container maxW={{ base: 'sm', md: 'md', lg: 'lg' }} py='2rem' textAlign={'center'}>
            <Image src={Coming} alt='image' />
            <Text fontSize={'24px'} my='1.5rem'>We will notify you soon as it's available.</Text>
            <Button 
            as={'a'} 
            href='/dashboard' 
            textTransform='uppercase' 
            p={{ base: '15px 20px', lg: '24px 48px' }} 
            borderRadius='8px' 
            _hover={{
                bg: 'blue.500',
            }}
            color={`${colors.lightColor}`} 
            bg={`${colors.primaryColor}`}
            >
            Go back home
            </Button>
        </Container>
    </div>
  )
}
