import React, { useEffect, useState } from 'react'
import SubNav from '../../../components/sub-nav';
import { Container, Heading, Select, Stack, Button, Input, Table, Tr, Th, Td, Thead, Tbody, Flex, HStack, TableContainer, Spinner, FormControl, Box } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { CurrencyFormatter, ParseSeconds, ApprovalStatus, ReferenceGenerator } from '../../../utils/common';
import Pagination from '../../../components/pagination';
import TaskNav from '../../../components/tasknav';
import { colors } from '../../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';


const Tasks = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const navigate = useNavigate();
  const toast = useToast();
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("ALL");
  const [approvalStatus, setApprovalStatus] = useState("ALL");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAvailableTask(newPage);
  }

  const toggleStatus = async (event, taskId, status) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/updateTask`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          task_id: taskId,
          status: status

        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        let index = tasks.findIndex(task => task.id == taskId);
        let newTasks = [...tasks];
        newTasks[index].status = status;
        setTasks(newTasks);
      }

    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const revokeTask = async (event, taskId) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/revokeTask`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          task_id: taskId,
        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        let index = tasks.findIndex(task => task.id == taskId);
        let newTasks = [...tasks];
        newTasks[index].status = "REVOKED";
        setTasks(newTasks);
      }

    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const plotFilter = () => {
    let filter = "";
    if(name.length > 1) {
      filter += "&name="+name;
    }
    if(status != "ALL") {
      filter += "&status="+status;
    }

    if(approvalStatus != "ALL") {
      filter += "&approval_status="+approvalStatus;
    }

    return filter;
  }

  const getAvailableTask = async (pageNumber) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/getTask?page=${pageNumber}${plotFilter()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setTasks(data.data.data)
      setLastPage(data.data.last_page)





    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  useEffect(() => {
    getAvailableTask(currentPage);
  }, []);


  return (
    <div>
      <Wrapper>
        <SubNav title={'My Task'} />
        <TaskNav />
        <HStack justifyContent="flex-center">
          <Button
            loadingText="Loading"
            bg={`${colors.primaryColor}`}
            color={'white'}
            margin={'1rem'}

            _hover={{
              bg: 'blue.500',
            }}
            onClick={() => navigate('/dashboard/tasks/tasks/create')}
          >
            Create New Task
          </Button>
        </HStack>
        <Flex justifyContent='center' alignItems='end' gap={'2rem'} mx='2rem'>
        <FormControl>
            <label style={{ fontWeight: '500' }}>Search</label>
            <Input onChange={(e) => setName(e.target.value)} value={name} border={`1px solid ${colors.primaryColor}`} variant='filled' color={'#aaa'} />
            
          </FormControl>
          <FormControl>
            <label style={{ fontWeight: '500' }}>Status</label>
            <Select onChange={(e) => setStatus(e.target.value)} value={status} border={`1px solid ${colors.primaryColor}`} variant='filled' color={'#aaa'}>
              <option value="ALL">ALL</option>
              <option value="ACTIVE">ACTIVE</option>
              <option value="INACTIVE">INACTIVE</option>
            </Select>
          </FormControl>
          <FormControl>
            <label style={{ fontWeight: '500' }}>Approval Status</label>
            <Select onChange={(e) => setApprovalStatus(e.target.value)} value={approvalStatus} border={`1px solid ${colors.primaryColor}`} variant='filled' color={'#aaa'}>
              <option value="ALL">ALL</option>
              <option value="PENDING">PENDING</option>
              <option value="APPROVED">APPROVED</option>
              <option value="REJECTED">REJECTED</option>
            </Select>
          </FormControl>

          <FormControl>
            <Button
              loadingText="Loading"

              type='submit'
              bg={`${colors.primaryColor}`}
              color={'white'}
              mt={'10px'}
              _hover={{
                bg: 'blue.500',
              }}
              onClick={() => getAvailableTask(currentPage)}
            >Filter</Button>
          </FormControl>
          
        </Flex>
       

        <TableContainer mt='2rem' mx='2rem'>
          <Table variant="simple" colorScheme="blue"  >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Name</Th>
                <Th>Execution Time</Th>
                <Th>Amount / Job</Th>
                <Th>Total Amount</Th>
                <Th>Approval Status</Th>
                <Th>Max Jobs</Th>
                <Th>Task Status</Th>
                <Th>Total Jobs</Th>

                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading() && <Tr>
                <Spinner />
              </Tr>}
              {tasks.map((task) => (

                <Tr key={task?.id}>
                  <Td>#{ReferenceGenerator(task?.id, task?.created_at)}</Td>
                  <Td>{task?.name}</Td>
                  <Td>{ParseSeconds(task?.execution_time_in_seconds)}</Td>
                  <Td>{CurrencyFormatter(task?.amount_per_task)}</Td>
                  <Td>{CurrencyFormatter(task?.staked_amount)}</Td>
                 
                  <Td><ApprovalStatus status={task?.approval_status} /></Td>
                  <Td>{task?.max_task}</Td>
                  <Td><ApprovalStatus status={task?.status} /></Td>
                  <Td>{task?.sub_tasks?.length}</Td>

                  <Td>
                    <Stack direction={['column', 'row']} spacing={['1', '2']} align="center">

                      <Button
                        loadingText="Loading"
                        size="sm"
                        type='submit'
                        bg={'red.400'}
                        color={'white'}
                        margin={'2px'}
                        _hover={{
                          bg: 'red.500',
                        }}
                        onClick={(e) => toggleStatus(e, task.id, (task.status == 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'))}
                      >{task.status == 'ACTIVE' ? 'Deactivate' : 'Activate'}</Button>
                    </Stack>
                    <Button
                      loadingText="Loading"
                      size="sm"
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      margin={'2px'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      onClick={(e) => revokeTask(e, task.id)}
                    >Revoke</Button>

                    <Button
                      loadingText="Loading"
                      size="sm"
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      margin={'2px'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      onClick={() => navigate(`/dashboard/tasks/tasks/${task.id}/details`)}
                    >View</Button>

                    <Button
                      loadingText="Loading"
                      size="sm"
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      margin={'2px'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      onClick={() => navigate(`/dashboard/tasks/tasks/${task.id}/edit`)}
                    >Edit</Button>

                  </Td>
                </Tr>
              ))}


            </Tbody>
          </Table>
        </TableContainer>
        <Pagination currentPage={currentPage} totalPages={lastPage} onPageChange={handlePageChange} />


      </Wrapper>
    </div>
  )
}

export default Tasks



const Wrapper = styled.div`
  background: #F3F3F3;
  padding-bottom: 4rem;
`;




