import { Flex, Heading, Text, Container } from '@chakra-ui/react'
import React from 'react'
import SubNav from '../../../components/sub-nav'
import { InputContainer } from '../../../styles/wallet.style'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'

const Transfer = () => {
  const Amount = Cookies.get('Amount')
  return (
    <Wrapper>
      <SubNav title='Bank Transfer' />

      <Container maxW={{ base: 'sm', lg: 'lg'}}
        my='2rem'
      >
        <Heading fontSize={'24px'} fontWeight='400' lineHeight={'33px'} mb='1rem'>Fund method by Bank Transfer</Heading>

        <form>
          <InputContainer>
              <h3>₦</h3>
              <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)} type='text' placeholder='6,000' value={Amount} readOnly />
          </InputContainer>

          <label>Bank</label>
          <InputContainer>
              <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)} type={'text'} placeholder='Providus Bank' />
          </InputContainer>

          <label>Account Number</label>
          <InputContainer>
              <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)} type={'text'} placeholder='1012546771' />
          </InputContainer> 

          <label>Account Name</label>
          <InputContainer>
              <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)} type='text' value='Bawa - 09034043088' placeholder='Bawa - 09034043088' readOnly />
          </InputContainer>
        </form>

        <Text mt='2rem'>By choosing Bank transfer option, you agree to make an <br /> internet transfer funding mode for your transaction</Text>
      </Container>
    </Wrapper>
  )
}

export default Transfer


const Wrapper = styled.div`
    background: #F3F3F3;
    /* padding: 1rem 0; */
    padding-bottom: 4rem;

    @media(max-width: 992px) {
      br {
        display: none;
      }
    }
`;