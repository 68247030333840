import { data, electricity, p2p, tv } from "../../assets/utils";
import { transfer, airtime, card } from '../../assets/dashboard';
import { Utils } from '../../styles/dasboard.style';
import { Button, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import styled from '@emotion/styled';
import MobileStoreButton from 'react-mobile-store-button';
import { CurrencyFormatter } from "../../utils/common";
import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import copy from 'clipboard-copy';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { decrement, increment } from "../../store/reducers/loading";
import Cookies from "js-cookie";
import { colors } from "../../styles/theme";
import { MdContentCopy } from "react-icons/md";


const utilsData = [
  {
    img: data,
    name: 'Data',
    url: '/dashboard/utilities/data',
  },
  {
    img: data,
    name: 'Sme Data',
    url: '/dashboard/utilities/sme',
  },
  {
    img: electricity,
    name: 'Electricity',
    url: '/dashboard/utilities/electricity',
  },
  {
    img: airtime,
    name: 'Airtime',
    url: '/dashboard/utilities/airtime',
  },
  {
    img: tv,
    name: 'Cable TV',
    url: '/dashboard/utilities/cable',
  },
  {
    img: transfer,
    name: 'Bank Transfer',
    url: '/dashboard/utilities/transfer',
  },
  {
    img: transfer,
    name: 'Wallet Transfer',
    url: '/dashboard/utilities/wallet-transfer',
  },
  {
    img: p2p,
    name: 'GIGS',
    url: '/dashboard/tasks/market',
  },
  {
    img: p2p,
    name: 'API Documentation',
    url: 'https://documenter.getpostman.com/view/8853142/2sA3Bq3qkk',
    target: '_blank'
  },
]


const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef();
  const cancelRefInformation = React.useRef();
  const [openInformation, setOpenInformation] = useState(false);
  const [referalCode, setReferalCode] = useState('')
  const linkToCopy = `${window.location.origin}/auth/register?ref=${referalCode}`;
  const codeToCopy = referalCode;
  const [linkCopied, setLinkCopied] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);
  let toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCopyClick = async () => {
    try {
      await copy(linkToCopy);
      setLinkCopied(true);

      // Reset isCopied to false after 3 seconds
      setTimeout(() => {
        setLinkCopied(false);
      }, 3000);
    } catch (error) {
      console.error('Copy to clipboard failed: ', error);
    }
  };
  const handleCodeCopy = async () => {
    try {
      await copy(codeToCopy);
      setCodeCopied(true);

      // Reset isCopied to false after 3 seconds
      setTimeout(() => {
        setCodeCopied(false);
      }, 3000);
    } catch (error) {
      console.error('Copy to clipboard failed: ', error);
    }
  };
  const handleGetData = async () => {

    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status == 401) {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if (response.status > 300) {
        if (response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setReferalCode(data.data.referal_code.code);
      if(localStorage.getItem("isFirstVisit") != "1") {
        setOpenInformation(true);
        localStorage.setItem("isFirstVisit", "1");
      }
     
      
      // console.log(data)


    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
        title: "Netork Error",
        status: 'error',
        description: "Check you internet connection",
        isClosable: true,
      })
    }
  }
  useEffect(() => {
    handleGetData();
  }, []);
  const upgradeAccount = async () => {
    onClose()
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me/upgrade`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.status == 401) {
        navigate("/auth/login");
      }
      if (response.status == 401) {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if (response.status > 300) {
        if (response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (response.status > 300) {
        if (response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }



    } catch (err) {
      dispatch(decrement());
      // Handle errors


      toast({
        title: "Netork Error",
        status: 'error',
        description: "Check you internet connection",
        isClosable: true,
      })
    }
  }
  const iOSUrl = 'https://play.google.com/store/apps/details?id=ng.suta';
  return (
    <Wrapper>
      <Flex justify={'center'} align={{ base: 'center', lg: 'start' }} gap={'2rem'} flexDir={{ base: 'column', lg: 'row' }}>
        <Utils>
          {utilsData.map((item, index) => (
            <a href={item.url} target={item.target} className='box' key={index}>
              <img src={item.img} alt={item.name} />
              <p>{item.name}</p>
            </a>
          ))}
        </Utils>

        <Flex flexDir={'column'} w={{ base: '80%', md: '40%', lg: '30%' }}>
          <Flex as={'a'} href='/dashboard/referral' justifyContent={'center'} alignItems='center' gap={{ base: '1rem', lg: '.6rem' }} bg='#fff' borderRadius={'6px'} p='1rem' >
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
              <path d="M27 0C41.9117 0 54 12.0883 54 27C54 41.9117 41.9117 54 27 54C12.0883 54 0 41.9117 0 27C0 12.0883 12.0883 0 27 0Z" fill="#1AAC33" />
              <path d="M40.209 23.5449C40.6458 23.5449 41 23.1907 41 22.7539V19.5898C41 19.2608 40.7963 18.9661 40.4884 18.8498L27.7794 14.051C27.5993 13.983 27.4006 13.983 27.2206 14.051L14.5116 18.8498C14.2037 18.9661 14 19.2608 14 19.5898V22.7539C14 23.1907 14.3542 23.5449 14.791 23.5449H17.1641V33.2259C16.2434 33.5524 15.582 34.4316 15.582 35.4629V36.3894C14.6612 36.7159 14 37.5956 14 38.6269V40.2089C14 40.6458 14.3542 41 14.791 41C16.0632 41 38.9367 41 40.209 41C40.6458 41 41 40.6458 41 40.2089V38.6269C41 37.5956 40.3387 36.7159 39.4179 36.3894V35.4629C39.4179 34.4316 38.7566 33.5524 37.8359 33.2259V23.5449H40.209ZM22.2123 39.4179H15.582V38.6269C15.582 38.1907 15.9369 37.8359 16.373 37.8359H23.0033L22.2123 39.4179ZM17.1641 36.2539V35.4629C17.1641 35.0267 17.5189 34.6718 17.9551 34.6718C18.1592 34.6718 24.4167 34.6718 24.5853 34.6718L23.7943 36.2539H17.1641ZM18.7461 33.0898V23.5449H20.3281V33.0898H18.7461ZM21.9102 33.0898V23.5449H25.0742V33.0898H21.9102ZM23.9811 39.4179L26.3541 34.6718H28.6459L31.019 39.4179H23.9811ZM26.6562 33.0898V23.5449H28.3437V33.0898H26.6562ZM30.4147 34.6718C30.5829 34.6718 36.8409 34.6718 37.0449 34.6718C37.4811 34.6718 37.8359 35.0267 37.8359 35.4629V36.2539H31.2057L30.4147 34.6718ZM29.9258 33.0898V23.5449H33.0898V33.0898H29.9258ZM34.6719 33.0898V23.5449H36.2539V33.0898H34.6719ZM38.6269 37.8359C39.0631 37.8359 39.4179 38.1907 39.4179 38.6269V39.4179H32.7877L31.9967 37.8359H38.6269ZM15.582 21.9629V20.1367L27.5 15.6365L39.4179 20.1367V21.9629C36.9716 21.9629 16.2159 21.9629 15.582 21.9629Z" fill="white" />
            </svg>
            <p>Refer and Earn</p>
          </Flex>

          <Flex flexDir={'column'} gap='1.4rem' mt={{ base: '2rem' }} bg='#fff' alignItems={'center'} borderRadius={'6px'} p='1rem'>

            <MobileStoreButton
              store="android"
              url={iOSUrl}
              linkProps={{ title: 'iOS Store Button' }}
            />


          </Flex>

          <Flex as={'button'} onClick={onOpen} flexDir={'column'} gap='1.4rem' mt={{ base: '2rem' }} bg='#fff' alignItems={'center'} borderRadius={'6px'} p='1rem'>


            <svg width="100px" height="100px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M256 29.816l-231 154v106.368l231-154 231 154V183.816zm0 128.043L105 259.783v90.283l151-101.925 151 101.925v-90.283zm0 112l-87 58.725v67.6l87-58 87 58v-67.6zm0 89.957l-87 58v64.368l87-58 87 58v-64.368z" /></svg>
            <h2>Become a Merchant Now at <b>{CurrencyFormatter(1000)}</b></h2>
            <p>Enjoy Cheaper prices</p>
            <p>Get API Access</p>


          </Flex>
        </Flex>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Upgrage To Merchant
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
              <p>{CurrencyFormatter(1000)} will be charged from your wallet</p>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={upgradeAccount} ml={3}>
                Upgrade
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={openInformation}
        leastDestructiveRef={cancelRefInformation}
        onClose={() => setOpenInformation(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>

              <Heading mt='1.2rem' textAlign={'center'} textTransform='capitalize'>Notice!!!</Heading>

            </AlertDialogHeader>

            <AlertDialogBody>
              Earn 1% of every amount funded by your referrals, forever. Refer now and start earning!

              <div>
                <Flex justify={'space-between'} align={'center'} textAlign={'center'} p='10px 15px' mt='.8rem' borderRadius={'14px'} bg={`${colors.primaryColor}`} color={`${colors.lightColor}`} cursor='pointer'> {linkToCopy} <MdContentCopy onClick={handleCopyClick} /></Flex>
                {linkCopied ? 'Copied!' : ''}
                <Flex justify={'space-between'} align={'center'} textAlign={'center'} p='10px 15px' mt='.8rem' borderRadius={'14px'} bg={`${colors.primaryColor}`} color={`${colors.lightColor}`} cursor='pointer'>{codeToCopy}<MdContentCopy onClick={handleCodeCopy} /></Flex>
                {codeCopied ? 'Copied!' : ''}
              </div>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRefInformation} onClick={() => setOpenInformation(false)}>
                Close
              </Button>

            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Wrapper>
  )
}

export default Dashboard

const Wrapper = styled.div`
    background: #F3F3F3;
    padding: 1rem 0;
    padding-bottom: 4rem;
`;