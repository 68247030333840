import React from 'react'
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Container, Flex, Text, Box, Image } from '@chakra-ui/react'
import SubNav from '../../../components/sub-nav'
import { colors } from '../../../styles/theme'
import { notifications } from './data'
import styled from '@emotion/styled'
import { settings } from '../../../assets/dashboard'
import DashboardFooter from '../../../components/dashboard-footer'
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import { parseDate } from '../../../utils/common';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Notification = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const [history, setHistory] = useState([])

  const handleGetData = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/wallet/history`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setHistory(data.data.data)
      // console.log(data)


    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      if (err.response && err.response.status === 401) {
        // Redirect to the login page if the API returns a 401 error
        navigate('/auth/login');
      }
    }
  }
  useEffect(() => {
    handleGetData();
  }, []);
  return (
    <>
      <Wrapper>
        <SubNav title={'Notifications'} />
        <Container maxW={{ base: 'sm', md: 'md', lg: 'lg' }} my='2rem'>
          <Text>Today</Text>
          <Box mt={'1.7rem'}>
            {history.map((item, index) => {
              return (
                <Flex mb={'1rem'} align={'ce'} shadow={'lg'} rounded={'lg'} padding={'0.7rem 0.9rem'} bg={'#fff'} justifyContent={'space-between'} key={index}>
                  <Flex gap={5}>
                    <Image w={{ base: '35px' }} src={settings} alt={item.title} />
                    <Box>
                      <Text>{item.description}</Text>
                      <Text>{parseDate(item.created_at)}</Text>
                    </Box>
                  </Flex>
                  <Box>
                    <Text>₦{item.amount}</Text>
                    <Text>{item.type}</Text>
                  </Box>
                </Flex>
              );
            })}
          </Box>

          {notifications.map((item, index) => (
            <Flex
              key={index}
              align='center'
              gap='.8rem'
              borderRadius='16px'
              p='1rem 1.5rem'
              my='.8rem'
              w={{ base: '100%', lg: '600px' }}
              bg={`${colors.lightBg}`}
            >
              <Image w={{ base: '35px' }} src={settings} alt={item.title} />
              <Box>
                <Text fontSize={{ base: '18px', lg: '20px' }}>{item.title}</Text>
                <Text fontSize={'10px'}>{item.time}</Text>
              </Box>
              <Text fontSize={{ base: '13px', lg: '14px' }} fontWeight='700' fontFamily={'raleway'} ml='1rem'>{item.details}</Text>
            </Flex>
          ))}


        </Container>

      </Wrapper>
      <DashboardFooter />
    </>
  )
}

export default Notification

const Wrapper = styled.div`
    background: #F3F3F3;
    /* padding: 1rem 0; */
    padding-bottom: 4rem;
`;