import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    count: 0,
  },
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      if(state.count > 0)
      state.count -= 1;
    },
  },
});

export const { increment, decrement } = loadingSlice.actions;
export default loadingSlice.reducer;
