import React, { useEffect, useState } from 'react'
import SubNav from '../../../../components/sub-nav';
import { Container, Heading, VStack, Text, Button, Badge, Divider, HStack, Box, Input, Spinner, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { CurrencyFormatter, ParseSeconds, ApprovalStatus } from '../../../../utils/common';
import Pagination from '../../../../components/pagination';
import TaskNav from '../../../../components/tasknav';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../../store/reducers/loading';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';


const TaskDetailsPage = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const { taskId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [task, setTask] = useState({});
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);




  const handleCreateSubTask = async () => {

    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/createSubTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          task_id: task.id,
          password: password
        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        navigate(`/dashboard/tasks/jobs/${data.data.id}/details`)
      }
    } catch (err) {
      // Handle errors
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const getSingleTask = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/${taskId}/getSingleTaskPublic`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setTask(data.data)
    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }
  useEffect(() => {
    getSingleTask();
  }, []);


  return (
    <div>
      <Wrapper>
        <SubNav title={'Task Details'} />
        <TaskNav />
        <Container maxW={{ base: 'lg', md: 'lg', lg: 'lg' }} my='2rem'>

          <Heading mb={4}>{task?.name}</Heading>

          <Box p={4} borderWidth="1px" borderRadius="lg">
            <VStack spacing={4} align="start">
              <Text fontSize="lg"><b>Task Name:</b> {task?.name}</Text>
              <Text fontSize="lg"><b>Description:</b> 
         
                  <Box
                    p={4}
                    borderRadius="md"
                    width="100%"
                    whiteSpace="pre-wrap"
                    dangerouslySetInnerHTML={{ __html: task?.description }}
                  />
            
              </Text>
              <Text fontSize="md"><b>Execution Time:</b> {ParseSeconds(task?.execution_time_in_seconds)}</Text>
              <Text fontSize="md"><b>Job Amount:</b> {CurrencyFormatter(task?.max_task_per_user)}</Text>
              <Text fontSize="md"><b>Task Status:</b> <ApprovalStatus status={task?.status} /></Text>
            </VStack>
          </Box>

          <Divider my={6} />

          <VStack spacing={4}>
            <InputGroup p={0} m={0}>
              <Input 
                type={showPassword ? 'text' : 'password'} 
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant='unstyled'
                required />
              <InputRightElement h={'full'}>
                <Button
                  variant={'ghost'}
                  onClick={() => setShowPassword((showPassword) => !showPassword)}>
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
        
            {isLoading() ?
              <Button size="lg" colorScheme="red"><Spinner /></Button>
              :
              <Button onClick={handleCreateSubTask} size="lg" colorScheme="red">Start Task</Button>

            }

            <small>Charge of {CurrencyFormatter(1)} will be deducted</small>
          </VStack>


        </Container>


      </Wrapper>
    </div>
  )
}

export default TaskDetailsPage

const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;




