// AuthRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../pages/auth/login';
import Register from '../pages/auth/register';
import Forget from '../pages/auth/forget-password';
import ConfirmPassword from '../pages/auth/confirm-password';
import OTP from '../pages/auth/otp';
import { ChangePassword } from '../pages/auth/change-password';

function AuthRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forget-password" element={<Forget />} />
      <Route path="/confirm-password" element={<ConfirmPassword />} />
      <Route path='/change-passowrd' element={<ChangePassword />} />
      <Route path="/otp" element={<OTP />} />
    </Routes>
  );
}

export default AuthRoutes;
