export const addUserToLocalStorage = (user) => {
	localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
	localStorage.removeItem("user");
};

export const getUserFromLocalStorage = () => {
	const result = localStorage.getItem("user");
	const user = result ? JSON.parse(result) : false;
	return user;
};

export const removeGroupFromLocalStorage = () => {
	localStorage.removeItem("group");
};
export const addGroupToLocalStorage = (user) => {
	localStorage.setItem("group", JSON.stringify(user));
};

export const getGroupFromLocalStorage = () => {
	const result = localStorage.getItem("group");
	const group = result ? JSON.parse(result) : {};
	return group;
};
export const addGroupsToLocalStorage = (user) => {
	localStorage.setItem("groups", JSON.stringify(user));
};

export const getGroupsFromLocalStorage = () => {
	const result = localStorage.getItem("groups");
	const groups = result ? JSON.parse(result) : [];
	return groups;
};
export const addSubcategoryToLocalStorage = (user) => {
	localStorage.setItem("subCategory", JSON.stringify(user));
};

export const getSubcategoryFromLocalStorage = () => {
	const result = localStorage.getItem("subCategory");
	const subCategory = result ? JSON.parse(result) : {};
	return subCategory;
};
export const addGroupProductsToLocalStorage = (user) => {
	localStorage.setItem("product", JSON.stringify(user));
};

export const getGroupProductsFromLocalStorage = () => {
	const result = localStorage.getItem("product");
	const subCategory = result ? JSON.parse(result) : [];
	return subCategory;
};
export const history = {
	navigate: null,
	location: null,
};
