import React, { useEffect, useState } from 'react'
import { Container, Heading, Text, Link, Input, Button } from '@chakra-ui/react'
import { styled } from 'styled-components'
import { colors } from '../styles/theme';
// import { useToast } from 'react-toastify';


const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  const [submited, setSubmitted] = useState(false);
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  // const toast = useToast()

  const submitRequest = async () => {
    try {
    
      const response = await fetch(`${baseUrl}/api/v1/deactivate_account/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          // email: email
        }), 
      });

      const data = await response.json();
      setSubmitted(true);

      // toast({
      //   title: data.message,
      //   status: 'success',
      //   description: "",
      //   isClosable: true,
      // })
      
    
    } catch (err) {
      
    }
  }

  useEffect(() => {
    if(email.length > 1) {
      setSubmitted(false);
    }else {
      setSubmitted(true);
    }
  },[email])
  
  return (
    <div>
        <Container maxW={{ base: 'sm', md: 'md', lg: 'container.sm' }} my='2rem' textAlign={'start'} fontFamily='raleway'>
            <Heading fontSize={'25px'} fontWeight='700' mb={'1rem'}>Delete Account</Heading>
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'>You can request for your data to be deleted, enter your email and send a request</Text>
            <br />
            <Text fontSize={'16px'} fontWeight='400' mb='.8rem'>Email Address</Text>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email Address" />
            <br />
            <br />
            {submited? 
            "An Email has been sent to your email please confirm your request"
            :
            <Button onClick={submitRequest}>Send Request</Button>

            }
           
        </Container>
    </div>
  )
}


const Span = styled.span`
    color: #000;
    font-weight: 700;
`;


export default DeleteAccount;