import React, { useEffect, useState } from 'react'
import SubNav from '../../../../components/sub-nav';
import { Container, Heading, Select, Stack, Button, Input, Table, Tr, Th, Td, Thead, Tbody, Flex, HStack, FormControl, Textarea, Spinner } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { CurrencyFormatter, ParseSeconds, ApprovalStatus } from '../../../../utils/common';
import TaskNav from '../../../../components/tasknav';
import { BsArrowRight } from 'react-icons/bs';
import { naira } from '../../../../assets/dashboard/utils';
import { colors } from '../../../../styles/theme';
import DurationInput from '../../../../components/DurationInput';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../../store/reducers/loading';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Create = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const { taskId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [maxTaskPerUser, setMaxTaskPerUser] = useState(0);
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const [defaultTime, setDefaultTime] = useState(0);


  const getSingleTask = async () => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/${taskId}/getSingleTask?status=SUBMITTED`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setName(data.data.name)
      setDescription(data.data.description)
      setMaxTaskPerUser(data.data.max_task_per_user)
      setTimeInSeconds(data.data.execution_time_in_seconds)
      setDefaultTime(data.data.execution_time_in_seconds)
    
    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/updateTask`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          task_id: taskId,
          name: name,
          description: description,
          max_task_per_user: maxTaskPerUser,
          execution_time_in_seconds: timeInSeconds
        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        toast({
          title: "success",
          status: 'success',
          description: data?.message,
          isClosable: true,
        })
        navigate(`/dashboard/tasks/tasks`)
      }

    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const quillStyles = {
    backgroundColor: 'white', // Background color
    border: `2px solid ${colors.primaryColor}`, // Border color
    height: '300px', // Set the desired height
  };

  useEffect(() => {
    getSingleTask()
  }, []);


  return (
    <div>
      <Wrapper>

        <SubNav title={'My Task'} />
        <TaskNav />
        <Container maxW={{ base: 'sm', md: 'md', lg: 'lg' }} my='2rem'>
          <Heading fontSize={'24px'} alignSelf={'center'} fontWeight='700' fontFamily={'inter'}>Edit Task</Heading>

          <form onSubmit={handleSubmit}>

            <FormControl>
              <label>Name</label>
              <Input
                height={'50px'}
                bg={'white'}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}

              />

            </FormControl>

            <FormControl>
              <label>Description</label>
              <ReactQuill
              style={quillStyles} 
              value={description}
              onChange={(e) => { setDescription(e) }}
              theme="snow"
              />

            </FormControl>
            <FormControl>
              <label>Execution Time Per Task</label>
              <DurationInput time={defaultTime} onChange={(e) => setTimeInSeconds(e)} />
              <small>{ParseSeconds(timeInSeconds)}</small>
            </FormControl>



            <FormControl>
              <label>Maximum Task Per User</label>
              <Input
                height={'50px'}
                bg={'white'}
                type="text"
                value={maxTaskPerUser}
                onChange={(e) => setMaxTaskPerUser(e.target.value)}

              />
            </FormControl>

            <Stack spacing={10} pt={2}>
              {isLoading() ?
                <Button
                  loadingText="Loading"
                  size="lg"

                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  <Spinner />
                </Button>
                :
                <Button
                  loadingText="Loading"
                  size="lg"
                  type='submit'
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Continue <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
                </Button>
              }

            </Stack>
          </form>
        </Container>




      </Wrapper>
    </div>
  )
}

export default Create



const Wrapper = styled.div`
  background: #F3F3F3;
  padding-bottom: 4rem;
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;

    height: 60px;
    
    border-radius: 12px;
    border: 1px solid ${colors.textColor};
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 8px 10px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    width: 55px;
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }

  img {
    align-self: center;
    margin: 0 1rem;
  }
`;




