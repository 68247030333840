import { MdArrowForwardIos } from 'react-icons/md'

export const settings = [
    {
      name: 'Change Password',
      icon: <MdArrowForwardIos />,
      url: '/dashboard/settings/change-password',
    },
    // {
    //     name: 'Notification Settings',
    //     icon: <MdArrowForwardIos />,
    //     url: '/dashboard/settings',
    // },
    {
        name: 'Privacy Policy',
        icon: <MdArrowForwardIos />,
        url: '/privacy-policy',
    },
    // {
    //     name: 'Biometric',
    //     icon: <MdArrowForwardIos />,
    //     url: '#',
    // },
    // {
    //     name: 'Terms of Use',
    //     icon: <MdArrowForwardIos />,
    //     url: '#',
    // },
    // {
    //     name: 'Service Agreement',
    //     icon: <MdArrowForwardIos />,
    //     url: '#',
    // },
    // {
    //     name: 'FAQ',
    //     icon: <MdArrowForwardIos />,
    //     url: '#',
    // },
  ]