import React from 'react'
import SubNav from '../../../components/sub-nav'
import { sizes } from '../../../styles/theme'
import { Container, Flex, Text, Switch } from '@chakra-ui/react'
import { colors } from '../../../styles/theme'
import { settings } from './data'


const ProfileSettings = () => {
  return (
    <div>
        <SubNav title={'Settings'} />
        <Container mt='2rem' placeContent={'center'} maxWidth={'lg'}>
          <Flex 
            cursor='pointer'
            textAlign='start'
            justify={'space-between'} 
            align='center'
            gap={'2rem'}
            fontSize={'18px'}
            borderRadius='20px' 
            p='1rem 1.5rem' w={'400px'} 
            bg={'#F5F7FB'} 
            my='.5rem'
          >
            <Text textTransform={'capitalize'}>Dark mode</Text>
            <Switch id='isFocusable' isFocusable isDisabled />
          </Flex>
          {settings.map((item, index) => (
            <Flex 
              as={'a'}
              key={index} 
              cursor='pointer'
              href={item.url}
              textAlign='start'
              justify={'space-between'} 
              align='center'
              gap={'2rem'}
              fontSize={'18px'}
              borderRadius='20px' 
              p='1rem 1.5rem' w={'400px'} 
              bg={'#F5F7FB'} 
              my='.5rem'
            >
              <Text textTransform={'capitalize'}>{item.name}</Text>
              <Text color={`${colors.primaryColor}`}>{item.icon}</Text>
            </Flex>
          ))}
        </Container>
    </div>
  )
}

export default ProfileSettings