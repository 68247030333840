import React, { useEffect, useState } from 'react'
import SubNav from '../../../components/sub-nav';
import { Select, Button, Table, Tr, Th, Td, Thead, Tbody, Flex, FormControl, TableContainer, Spinner, Input, HStack, Box, Tooltip } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { CurrencyFormatter, ParseSeconds, ReferenceGenerator, parseDate } from '../../../utils/common';
import Pagination from '../../../components/pagination';
import TaskNav from '../../../components/tasknav';
import { colors } from '../../../styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';


const Market = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const navigate = useNavigate();
  const toast = useToast()
  const [sortBy, setSortBy] = useState("created_at");
  const [order, setOrder] = useState("desc");
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [name, setName] = useState("");


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAvailableTask(newPage);
  }


  const getAvailableTask = async (pageNumber) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/getTaskPublic?sort_by=${sortBy}&order=${order}&page=${pageNumber}${name.length > 1? "&name="+name : ""}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setTasks(data.data.data)
      setLastPage(data.data.last_page)

    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  useEffect(() => {
    getAvailableTask(currentPage);
  }, []);


  return (
    <div>
      <Wrapper>
        <SubNav title={'Market Place'} />
        <TaskNav />
        <HStack justifyContent="flex-center">
        <Tooltip label="Add your own task for other users to carry out for you">
          <Button
            loadingText="Loading"
            bg={`${colors.primaryColor}`}
            color={'white'}
            margin={'1rem'}

            _hover={{
              bg: 'blue.500',
            }}
            onClick={() => navigate('/dashboard/tasks/tasks/create')}
          >
            Create New Task
          </Button>
          </Tooltip>
        </HStack>
        <Flex justifyContent='center' alignItems='end' gap={'2rem'} mx='2rem'>
        <FormControl>
            <label style={{ fontWeight: '500' }}>Search:</label>
            <Input onChange={(e) => setName(e.target.value)} value={name} border={`1px solid ${colors.primaryColor}`} variant='filled' color={'#aaa'} />
            
          </FormControl>
          <FormControl>
            <label style={{ fontWeight: '500' }}>Filter By:</label>
            <Select onChange={(e) => setSortBy(e.target.value)} value={sortBy} border={`1px solid ${colors.primaryColor}`} variant='filled' color={'#aaa'}>
              <option value="created_at">Date</option>
              <option value="execution_time_in_seconds">Execution Time</option>
              <option value="amount_per_task">Amount</option>
            </Select>
          </FormControl>
          <FormControl>
            <label style={{ fontWeight: '500' }}>Order:</label>
            <Select onChange={(e) => setOrder(e.target.value)} value={order} border={`1px solid ${colors.primaryColor}`} variant='filled' color={'#aaa'}>
              <option value="asc">Ascending</option>
              <option value="desc">Decending</option>
            </Select>
          </FormControl>

          <FormControl>
          <Tooltip label="Filter by parameters shown on the dropdown">
            <Button
              loadingText="Loading"

              type='submit'
              bg={`${colors.primaryColor}`}
              color={'white'}
              mt={'10px'}
              _hover={{
                bg: 'blue.500',
              }}
              onClick={() => getAvailableTask(currentPage)}
            >Filter</Button>
            </Tooltip>
          </FormControl>
        </Flex>

        <TableContainer mt='2rem' mx='2rem'>
          <Table variant="simple" colorScheme="blue" >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Name</Th>
                <Th>Execution Time</Th>
                <Th>Amount</Th>
                <Th>Created Date </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading() && <Tr>
                <Spinner />
              </Tr>}
              {tasks.map((task) => (
                <Tr key={task?.id}>
                  <Td>#{ReferenceGenerator(task?.id, task?.created_at)}</Td>
                  <Td>{task?.name}</Td>
                  <Td>{ParseSeconds(task?.execution_time_in_seconds)}</Td>
                  <Td>{CurrencyFormatter(task?.amount_per_task)}</Td>
                
                  <Td>{parseDate(task?.created_at)}</Td>
                  <Td>
                  <Tooltip label="Want to carry out this task? view more">
                    <Button
                      loadingText="Loading"
                      size="sm"
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      onClick={() => navigate(`/dashboard/tasks/market/${task.id}/details`)}
                    >View Details</Button>
                    </Tooltip>
                  </Td>
                </Tr>
              ))}

            </Tbody>
          </Table>
        </TableContainer>

        <Pagination currentPage={currentPage} totalPages={lastPage} onPageChange={handlePageChange} />


      </Wrapper>
    </div>
  )
}

export default Market

const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;




