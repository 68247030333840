// Debit Card
import React from 'react'
import { 
  Card, 
  CardBody, 
  CardHeader, 
  Box, 
  Text, 
  Heading, 
  Container, 
  Flex, 
  Link, 
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Button,
  Input,
} from '@chakra-ui/react'
import SubNav from '../../../components/sub-nav'
import { colors } from '../../../styles/theme'
import { BsArrowRight } from 'react-icons/bs'
import styled from '@emotion/styled'
import Cookies from 'js-cookie'

const Debit = () => {
  const Amount = Cookies.get('Amount')

  return (
    <Wrapper>
      <SubNav title={'Debit Card'} />
      <Container maxW={{ base: 'sm', md: 'md', lg: 'lg'}}>
            <Card 
                maxW={{ base: 'sm', lg: 'md' }} 
                textAlign='center' 
                alignSelf={'center'} 
                my={{ base: '2rem', lg: '4rem' }}
                boxShadow={'lg'} 
                borderRadius='lg'
                display='flex'
                justify={'center'}
                align='center'
                flexDir={'column'}
            >
                <CardHeader>
                    <Heading fontFamily={'Raleway'} fontSize={{ base: '24px', lg: '32px' }}>Debit Card</Heading>
                </CardHeader>
                <Box width={'80%'} height='3px' bg={`${colors.primaryColor}`} />
                <CardBody textAlign={'center'} mb='1.4rem' color={`${colors.primaryColor}`}>
                    <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Enter Card Details Below</Text>
                    <Box
                      bg={'transparent'}
                      px={{ base: 2, lg: 8 }}>
                      <Stack spacing={2}>
                        <FormControl id="amount">
                          <FormLabel fontSize={{ base: '15px', lg: '18px'}}>Amount</FormLabel>
                          <Input type="text" value={Amount} placeholder='6,000' readOnly/>
                        </FormControl>

                        <FormControl id="card-number">
                          <FormLabel fontSize={{ base: '15px', lg: '18px'}}>Card Number</FormLabel>
                          <Input type="tel" placeholder='1234 567 8912 3456' maxLength={'19'} />
                        </FormControl>
                   
                        <FormControl id="card-name">
                          <FormLabel fontSize={{ base: '15px', lg: '18px'}}>Card Name</FormLabel>
                          <Input type="text" placeholder='John A. Doe' />
                        </FormControl>

                        <HStack>
                          <Box>
                            <FormControl id="expDate">
                              <FormLabel fontSize={{ base: '15px', lg: '18px'}}>Exp. Date</FormLabel>
                              <Input type="tel" placeholder='MM / YY' />
                            </FormControl>
                          </Box>
                          <Box>
                            <FormControl id="exp">
                              <FormLabel fontSize={{ base: '15px', lg: '18px'}}>CVC</FormLabel>
                              <Input type="text" placeholder='CVC' />
                            </FormControl>
                          </Box>
                        </HStack>

                        <FormControl id="card-pin">
                          <FormLabel fontSize={{ base: '15px', lg: '18px'}}>Card PIN</FormLabel>
                          <Input type="text" placeholder='****' />
                        </FormControl>

                        <Stack spacing={10} pt={2}>
                          <Button
                            fontSize={{ base: '16px', lg: '18px'}}
                            size="lg"
                            bg={'blue.400'}
                            color={'white'}
                            _hover={{
                              bg: 'blue.500',
                            }}>
                            Continue <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
                          </Button>
                        </Stack>
                      </Stack>
                    </Box>
                </CardBody>
            </Card>
        </Container>
    </Wrapper>
  )
}

export default Debit

const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;

`;