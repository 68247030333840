import styled from "styled-components";
import { colors } from "./theme";
const { primaryColor } = colors;

export const Container = styled.div`
	width: 100vw;
	/* height: 100vh; */

  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 992px) {
    width: 100%;
  }
`;
export const Image = styled.div`
	width: 150px;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
	}

	@media (max-width: 992px) {
		width: 100px;
		height: 35px;
	}
`;

export const Wrapper = styled.div`
	width: 50%;
	padding: 2rem;

  
	h2 {
      color: ${colors.darkColor};
      
      margin-bottom: 5px;
      font-size: 32px;
      font-weight: 700;
      line-height: 24px; /* 75% */
      @media (max-width: 992px) {
        font-size: 24px;
      }
	}
	p {
      color: ${colors.textColor};
      font-size: 18px;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
	}
	span.otp-mail {
		color: ${colors.primaryColor};
	}
	@media (max-width: 992px) {
		width: 100%;
		padding: 1rem;
	}
`;
export const Form = styled.form`
	background: white;
	border-radius: 20px;
	padding: 15px 0;
	width: 500px;
	margin: 10px auto;
	label {
		color: #0d0b33;
		display: inline-block;
		padding-bottom: 10px;
		font-weight: 500;
	}
	p {
		font-size: 14px;
	}
	#terms {
		display: flex;
		input {
			margin-right: 10px;
			cursor: pointer;
		}
	}
	#already-user {
		text-align: center;
		a {
			color: ${primaryColor};
			:hover {
				text-decoration: underline;
			}
		}
	}
	@media (max-width: 992px) {
		width: 100%;
		padding: 1rem;
	}
`;
export const InputContainer = styled.div`
	width: 100%;
	margin-bottom: 20px;
	height: 60px;
	border-radius: 10px;
    border: 1px solid ${colors.primaryColor};
    background: transparent;
	display: flex;
	align-items: center;
	color: #8e8c94;
	:focus-within {
		border: none;
		background: transparent;
	}
	input {
		border: none;
		outline: none;
		height: 100%;
		border-radius: 0 10px 10px 0;
		width: 100%;
		background: transparent;
		color: ${colors.textColor};
	}

	img {
      margin: 4px 10px;
      font-size: 18px;
      color: ${colors.primaryColor};
	}
	
	@media (max-width: 992px) {
		
		img {
		  font-size: 14px;
		}
		input, input::placeholder {
			font-size: 14px;
		}
	}
`;
export const SubmitBtn = styled.button`
	border: none;
    border-radius: 10px;
    margin: 20px 0;
	width: 100%;
    height: 50px;
  
	background: ${primaryColor};
	color: white;
  
	font-weight: 600;
	font-size: 16px;
    text-transform: uppercase;

	cursor: pointer;
`;

export const Help = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	a {
		color: ${colors.primaryColor};
		:hover {
			color: ${primaryColor};
			text-decoration: underline;
		}
	}
	@media (max-width: 992px) {
		font-size: 12px;
	}
`;
export const ErrorCont = styled.div`
	padding: 5px;
	background: #ff000077;
	color: white;
	border-radius: 99px;
	width: 60%;
	margin: 10px auto;
	text-align: center;
	font-size: 14px;
`;
export const SideImg = styled.div`
	width: 100%;
	background: #fff;
	@media (max-width: 992px) {
		display: none;
	}
`;
export const SideImage = styled.img`
	width: 100%;
	height: 100%;
`;
