//FundRoutes
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Transfer from '../pages/dasboard/fund/transfer';
import Debit from '../pages/dasboard/fund/debit';
import Voucher from '../pages/dasboard/fund/voucher';
import USSD from '../pages/dasboard/fund/ussd';

function FundRoutes() {
  return (
    <Routes>
        <Route path='/debit' element={<Debit />} />
        {/* <Route path='/transfer' element={<Transfer />}/> */}
        <Route path='/voucher' element={<Voucher />}/>
        {/* <Route path='/ussd' element={<USSD />} /> */}
    </Routes>
  )
}

export default FundRoutes