import React, { useEffect } from "react";
import { useState } from 'react';
import { Outlet } from "react-router-dom";
import { Container } from "../style";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import SmallSidebar from "../../layout/landingLayout/mobileNav";

const LandingWrapper = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
	const toggleSideBar = () => {
		setSidebarOpen(!isSidebarOpen);
	}; 
    useEffect(() => {
        localStorage.setItem("isFirstVisit", "0");
      }, []);

    return (
        <Container>
            <SmallSidebar
                isSidebarOpen={isSidebarOpen}
                toggleSideBar={toggleSideBar}
            />
            <Navbar/>
            {/*<SmallSidebar />*/}
            <Outlet />
            {/*<Footer />*/}
        </Container>
    );
};

export default LandingWrapper;