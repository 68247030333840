'use client'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Image,
  Link,
  Button
} from '@chakra-ui/react'
import { sutaIcon } from '../../assets/dashboard'
import { Wrapper } from './style'
import { colors } from '../../styles/theme';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { FaBell } from 'react-icons/fa'
import UserDetails from './user-details'
import TopNav from './topnav'

const Links = ['Home', 'Transactions', 'My Account']
const url = [ '/dashboard', '/dashboard/transactions', '/dashboard/my-account' ]



export default function DashboardNav() {

  return (
    <>
      <Wrapper bg={useColorModeValue('blue.100', 'blue.900')} px={{ base: 2, lg: 8}}>
        <TopNav />
        <UserDetails />
      </Wrapper>
    </>
  )
}

