import React from 'react'
import { Button, Container, Heading, Text } from '@chakra-ui/react';
import Notfound from "../assets/undraw_page_not_found.svg";
import { colors } from '../styles/theme';

const NotFound = () => {
    return (
        <Container maxW={{ base: 'sm', lg: 'lg' }} my='2rem' textAlign={'center'} px={4}>
            <img src={Notfound}  alt={'page not found'}/>
            <Text fontSize={'24px'} my='1.5rem'>Ooops!...Page not found.</Text>
            <Button 
            as={'a'} 
            href='/' 
            textTransform='uppercase' 
            p={{ base: '15px 20px', lg: '24px 48px' }} 
            borderRadius='8px' 
            color={`${colors.lightColor}`} 
            bg={`${colors.primaryColor}`}
            >
            Go back home
            </Button>
        </Container>
    )
}

export default NotFound