import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

// styling input
import { Center, Heading } from '@chakra-ui/react'
import {
  Button,
  FormControl,
  Flex,
  Text,
  Stack,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react'
import { PinInput, PinInputField } from '@chakra-ui/react'

const OTP = () => {
  const [pinA, setPinA] = useState("");
  const [pinB, setPinB] = useState("");
  const [pinC, setPinC] = useState("");
  const [pinD, setPinD] = useState("");
  const [pinE, setPinE] = useState("");
  const [pinF, setPinF] = useState("");
  const [time, setTime] = useState(5*60);

  const formatedTime = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = time % 60;
    let prependValue = seconds < 10? '0' : '';
    return `${minutes}:${prependValue}${seconds}`;
  }

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  const handlePinAChange = (event) => {
    setPinA(event.target.value);
  }
  const handlePinBChange = (event) => {
    setPinB(event.target.value);
  }
  const handlePinCChange = (event) => {
    setPinC(event.target.value);
  }
  const handlePinDChange = (event) => {
    setPinD(event.target.value);
  }
  const handlePinEChange = (event) => {
    setPinE(event.target.value);
  }
  const handlePinFChange = (event) => {
    setPinF(event.target.value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if(time > 0) {
        setTime(time - 1);
      }
   
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!pinA || !pinB || !pinC || !pinC || !pinD || !pinE || !pinF) {
      return toast.error("please provide all values");
    }

    const otpPin = `${pinA}${pinB}${pinC}${pinD}${pinE}${pinF}`
    // // console.log(otpPin)
    Cookies.set('OTP', otpPin, { expires: 1 });
    navigate("/auth/confirm-password");
  }

  const resendOtp = async (event) => {
    setTime(60*5);
    event.preventDefault();

    try {
    
      const response = await fetch(`${baseUrl}/api/v1/reset_password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone_number: Cookies.get('phone_number'),
        }), // Convert the form data to JSON
      });

      const data = await response.json();
    

    
    } catch (err) {
      // Handle errors
   
      if (err.response && err.response.status === 401) {
        // Redirect to the login page if the API returns a 401 error
        navigate('/auth/login');
      }
    }


  };

  return (
    <div>
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'md'}
          p={6}
          my={10}>
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            Enter OTP
          </Heading>
          <Text fontSize={{ base: 'sm', sm: 'md' }}>
            We have sent code to your email <span className='otp-mail'>phone number</span>
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl my={'1rem'}>
              <Center>
                <HStack>
                  <PinInput size={'lg'}>
                    <PinInputField
                      value={pinA}
                      onChange={handlePinAChange}
                      required
                    />
                    <PinInputField
                      value={pinB}
                      onChange={handlePinBChange}
                      required
                    />
                    <PinInputField
                      value={pinC}
                      onChange={handlePinCChange}
                      required
                    />
                    <PinInputField
                      value={pinD}
                      onChange={handlePinDChange}
                      required
                    />
                    <PinInputField
                      value={pinE}
                      onChange={handlePinEChange}
                      required
                    />
                    <PinInputField
                      value={pinF}
                      onChange={handlePinFChange}
                      required
                    />
                  </PinInput>
                </HStack>
              </Center>
            </FormControl>
            <Stack spacing={6}>
              <Button
                type='submit'
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
              >
                Verify
              </Button>
              {time > 0? 
              <Text fontSize={'16px'} fontWeight='500' textAlign={'center'} color='#42A4D9'>Resend in {formatedTime(time)}</Text>
              
              :
              <Button onClick={resendOtp} fontSize={'16px'} fontWeight='500' textAlign={'center'} color='#42A4D9'>Re-send OTP</Button>
              }
              </Stack>
          </form>
        </Stack>
      </Flex>
    </div>
  )
}

export default OTP