import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
	getCategoriesAdminThunk,
	getProductsAdminThunk,
	getSubCategoriesAdminThunk,
} from "./adminThunk";

const initialState = {
	isLoading: false,
	categories: [],
	subCategories: [],
	products: [],
};

export const getCategoriesAdmin = createAsyncThunk(
	"admin/getCategories",
	getCategoriesAdminThunk
);
export const getSubCategoriesAdmin = createAsyncThunk(
	"admin/getSubCategories",
	getSubCategoriesAdminThunk
);
export const getProductsAdmin = createAsyncThunk(
	"admin/getProducts",
	getProductsAdminThunk
);

const adminSlice = createSlice({
	name: "admin",
	initialState,
	reducers: {
		
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCategoriesAdmin.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getCategoriesAdmin.fulfilled, (state, { payload }) => {
				state.isLoading = false;

				state.categories = payload;
			})
			.addCase(getCategoriesAdmin.rejected, (state, { payload }) => {
				state.isLoading = false;
			})
			.addCase(getSubCategoriesAdmin.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getSubCategoriesAdmin.fulfilled, (state, { payload }) => {
				state.isLoading = false;

				state.subCategories = payload;
			})
			.addCase(getSubCategoriesAdmin.rejected, (state, { payload }) => {
				state.isLoading = false;
			})
			.addCase(getProductsAdmin.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getProductsAdmin.fulfilled, (state, { payload }) => {
				state.isLoading = false;

				state.products = payload;
			})
			.addCase(getProductsAdmin.rejected, (state, { payload }) => {
				state.isLoading = false;
			});
	},
});

export const { testing } = adminSlice.actions;
export default adminSlice.reducer;