export const sizes = {
    wrapperWidth: "1128px"
}
export const breakpoints = {
    lg: "@media screen and (max-width: 1000px)",
    md: "@media screen and (max-width: 700px)",
    sm: "@media screen and (max-width: 500px)",
    xs: "@media screen and (max-width: 250px)"
}
export const colors = {
    primaryColor: "#42A4D9",
    textColor: "#7F879E",
    darkColor: "#0C0C0C",
    lightColor: '#f4f4f4',
    lightBg: 'rgba(255, 255, 255, 1)',
}