import React, { useEffect, useState } from 'react'
import SubNav from '../../../../components/sub-nav';
import { Container, Heading, VStack, Text, Button, Badge, Divider, HStack, Box, Input, Table, Thead, Tr, Th, Tbody, Stack, Td, Spinner, FormControl, Select, Flex, TableContainer } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Cookies from 'js-cookie'
import { CurrencyFormatter, ParseSeconds, ApprovalStatus, ReferenceGenerator, parseDate } from '../../../../utils/common';
import Pagination from '../../../../components/pagination';
import TaskNav from '../../../../components/tasknav';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../../store/reducers/loading';
import { colors } from '../../../../styles/theme';


const TaskDetailsPage = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const { taskId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [task, setTask] = useState({});
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("ALL");
  const allStatus = [
    'ALL','COMPLETED','FAILED','IN_PROGRESS','REJECTED','SUBMITTED'
  ];




  const handleCreateSubTask = async () => {

    const token = `Bearer ${Cookies.get('Token')}`
    try {
      dispatch(increment());
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/createSubTask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          task_id: task.id,
          password: password
        }),
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      if (!data.status) {
        // console.log(data.status)
        toast({
          title: "An error occurred",
          status: 'error',
          description: data?.message,
          isClosable: true,
        })
      } else {
        navigate(`/dashboard/tasks/jobs/${data.data.id}/details`)
      }
    } catch (err) {
      dispatch(decrement());
      // Handle errors
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }

  const getSingleTask = async (statusLocal = status) => {
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/task/${taskId}/getSingleTask?status=${statusLocal}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setTask(data.data)
    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }
  useEffect(() => {
    getSingleTask();
  }, []);


  return (
    <div>
      <Wrapper>
        <SubNav title={'Task Details'} />
        <TaskNav />


        <Heading mb={4}>#{ReferenceGenerator(task?.id, task?.created_at)}</Heading>

        <Box p={4} borderWidth="1px" borderRadius="lg">
          <VStack spacing={4} align="start">
            <Text fontSize="lg"><b>Task Name:</b> {task?.name}</Text>
            <Text fontSize="md"><b>Description:</b>
           
                  <Box
                    p={4}
                    border=""
                    borderRadius="md"
                    width="100%"
                    whiteSpace="pre-wrap"
                    dangerouslySetInnerHTML={{ __html: task?.description }}
                  />
                
            </Text>
            <Text fontSize="md"><b>Max Job Available:</b> {task?.max_task}</Text>
            <Text fontSize="md"><b>Max Job Per User:</b> {task?.max_task_per_user}</Text>
            <Text fontSize="md"><b>Execution Time:</b> {ParseSeconds(task?.execution_time_in_seconds)}</Text>
            <Text fontSize="md"><b>Job Amount:</b> {CurrencyFormatter(task?.amount_per_task)}</Text>
            <Text fontSize="md"><b>Total Amount:</b> {CurrencyFormatter(task?.staked_amount)}</Text>
            <Text fontSize="md"><b>Task Status:</b> <ApprovalStatus status={task?.status} /></Text>
            <Text fontSize="md"><b>Approval Status:</b> <ApprovalStatus status={task?.approval_status} /></Text>
            <Text fontSize="md"><b>Approval Note:</b> {task?.admin_note} </Text>
            <Text fontSize="md"><b>Created Date:</b> {parseDate(task?.created_at)}  </Text>
          </VStack>
        </Box>
        <Text fontSize="md"><b>Jobs Taken</b></Text>
        <Flex justifyContent='center' alignItems='end' gap={'2rem'} mx='2rem'>
          <FormControl>
            <label style={{ fontWeight: '500' }}>Status:</label>
            <Select onChange={(e) => {setStatus(e.target.value); getSingleTask(e.target.value); }} value={status} border={`1px solid ${colors.primaryColor}`} variant='filled' color={'#aaa'}>
              {allStatus.map(statusS => (
                <option value={statusS}>{statusS}</option> 
              )) }
              
            </Select>
          </FormControl>
          

         
        </Flex>
        <TableContainer mt='2rem' mx='2rem'>
        <Table variant="striped" colorScheme="teal" >
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Amount</Th>
              <Th>Status</Th>
              <Th>Settlement Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading() &&
              <Tr>
                <Spinner />
              </Tr>
            }
            {task?.sub_tasks?.map((subTask) => (

              <Tr key={subTask?.id}>
                <Td>#{ReferenceGenerator(subTask?.id, subTask?.created_at)}</Td>
                <Td>{CurrencyFormatter(subTask?.amount)}</Td>

                <Td><ApprovalStatus status={subTask?.task_status} /></Td>
                <Td><ApprovalStatus status={subTask?.settlement_status ? subTask?.settlement_status : "PENDING"} /></Td>

                <Td>
                  <Stack direction={['column', 'row']} spacing={['1', '2']} align="center">
                    <Button
                      loadingText="Loading"
                      size="sm"
                      type='submit'
                      bg={'blue.400'}
                      color={'white'}
                      margin={'2px'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      onClick={() => navigate(`/dashboard/tasks/jobs/${subTask.id}/details/true`)}
                    >View</Button>
                  </Stack>
                </Td>
              </Tr>
            ))}


          </Tbody>
        </Table>
        </TableContainer>


      </Wrapper>
    </div>
  )
}

export default TaskDetailsPage

const Wrapper = styled.div`
    background: #F3F3F3;
    padding-bottom: 4rem;
`;




