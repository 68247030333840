import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { colors } from '../styles/theme';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);

  const renderPageNumbers = () => {
    const pageNumbers = range(1, totalPages);

    return pageNumbers.map((page) => (
      <Button
        key={page}
        onClick={() => onPageChange(page)}
        variant={page === currentPage ? 'solid' : 'outline'}
        colorScheme={page === currentPage ? 'teal' : 'gray'}
        size="sm"
      >
        {page}
      </Button>
    ));
  };

  return (
    <Flex align="center" justify="center" mt={5}>
      <Box>
        <Text mr={2}>Page {currentPage} of {totalPages}</Text>
      </Box>
      <Box>
        <Button
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
          mr={2}
          size="sm"
        >
          Previous
        </Button>
      </Box>
      <Box colorScheme='blue' color='#fff' borderRadius={'8px'}>
        {renderPageNumbers()}
      </Box>
      <Box>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
          ml={2}
          size="sm"
        >
          Next
        </Button>
      </Box>
    </Flex>
  );
};

export default Pagination;
