import React from 'react'
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Container, Text, Flex, Heading } from '@chakra-ui/react'
import SubNav from '../../../components/sub-nav'
import { colors } from '../../../styles/theme'
import { MdContentCopy } from 'react-icons/md'
import DashboardFooter from '../../../components/dashboard-footer'
import copy from 'clipboard-copy';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../../store/reducers/loading';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

const Referral = () => {
  const loadingCount = useSelector((state) => state.loading.count);
  const dispatch = useDispatch();
  const toast = useToast();
  const isLoading = () => {
    // console.log(loadingCount);
    return loadingCount > 0;
  }
  const navigate = useNavigate();
  const [referalCode, setReferalCode] = useState('')

  const [linkCopied, setLinkCopied] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);

  const linkToCopy = `${window.location.origin}/auth/register?ref=${referalCode}`;
  const codeToCopy = referalCode;

  const handleGetData = async () => {
    
    try {
      dispatch(increment());
      const token = `Bearer ${Cookies.get('Token')}`
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if(response.status == 401)  {
        navigate("/auth/login");
      }
      const data = await response.json();
      dispatch(decrement());
      if(response.status > 300) {
        if(response.status == 500) {
          toast({
            title: "Something went wrong",
            status: 'error',
            description: "Please contact support",
            isClosable: true,
          })
        }
        toast({
          title: data.message,
          status: 'error',
          description: "",
          isClosable: true,
        })
        return;
      }
      setReferalCode(data.data.referal_code.code);
      // console.log(data)


    } catch (err) {
      dispatch(decrement());
      // console.log(err)
      toast({
          title: "Netork Error",
          status: 'error',
          description: "Check you internet connection",
          isClosable: true,
        })
    }
  }
  useEffect(() => {
    handleGetData();
  }, []);

  const handleCopyClick = async () => {
    try {
      await copy(linkToCopy);
      setLinkCopied(true);

      // Reset isCopied to false after 3 seconds
      setTimeout(() => {
        setLinkCopied(false);
      }, 3000);
    } catch (error) {
      console.error('Copy to clipboard failed: ', error);
    }
  };
  const handleCodeCopy = async () => {
    try {
      await copy(codeToCopy);
      setCodeCopied(true);

      // Reset isCopied to false after 3 seconds
      setTimeout(() => {
        setCodeCopied(false);
      }, 3000);
    } catch (error) {
      console.error('Copy to clipboard failed: ', error);
    }
  };
  return (
    <div>
      <SubNav title={'Referral'} />
      <Container maxW={'lg'} mb='4rem'>
        <Flex justify={'center'} align='center' flexDir={'column'} borderRadius={'40px'} bg={'#F5F7FB'} p='3rem 5rem'>
          <svg xmlns="http://www.w3.org/2000/svg" width="138" height="138" viewBox="0 0 138 138" fill="none">
            <path d="M122.034 137.872H15.0411C12.2383 137.872 9.9668 135.6 9.9668 132.798V50.9824H127.109V132.798C127.109 135.6 124.837 137.872 122.034 137.872Z" fill="#FFC73B" />
            <path d="M9.9668 50.9824H127.109V68.0072H9.9668L9.9668 50.9824Z" fill="#EFB025" />
            <path d="M137.077 33.2339V54.3259C137.077 56.5786 135.249 58.4035 132.996 58.4035H4.07764C1.82495 58.4035 0 56.5786 0 54.3259V33.2339C0 30.9812 1.82495 29.1562 4.07764 29.1562H132.996C135.249 29.1562 137.077 30.9812 137.077 33.2339Z" fill="#FFC73B" />
            <path d="M63.8782 38.8535C62.9882 39.8899 62.115 40.9409 61.2626 42.0035C58.0865 45.9556 55.1791 50.0971 52.551 54.3954C51.7426 55.7173 50.9624 57.0528 50.2052 58.404H22.4043C24.0285 54.7185 25.8095 51.1011 27.739 47.5652C31.2383 41.1501 35.2312 34.9945 39.6926 29.1567C40.7583 27.7627 41.8491 26.3853 42.9671 25.0289C43.6364 24.2111 44.3172 23.4048 45.0075 22.6016C47.5446 24.7873 50.0797 26.971 52.6179 29.1567C53.0111 29.4935 53.4043 29.8344 53.7965 30.1722C56.7917 32.7512 59.7869 35.3291 62.7811 37.9113C63.1482 38.2229 63.5122 38.5398 63.8782 38.8535Z" fill="#EFB025" />
            <path d="M105.358 58.404H77.5551C76.8011 57.0528 76.0178 55.7173 75.2093 54.3954C72.5812 50.0971 69.6707 45.9557 66.4977 42.0035C65.6422 40.941 64.769 39.8899 63.8779 38.8535C64.245 38.5398 64.6089 38.225 64.976 37.9113C67.9733 35.3291 70.9686 32.7481 73.9627 30.1722C74.356 29.8344 74.7492 29.4935 75.1424 29.1567C77.6795 26.971 80.2177 24.7873 82.7528 22.6016C83.4431 23.4026 84.1239 24.2111 84.7932 25.0268C85.9132 26.3853 87.0051 27.7627 88.0676 29.1567C92.5291 34.9945 96.522 41.1501 100.023 47.5651C101.953 51.101 103.734 54.7185 105.358 58.404Z" fill="#EFB025" />
            <path d="M68.5364 38.8545C67.6454 39.8899 66.7722 40.9409 65.9198 42.0035C62.7447 45.9556 59.8363 50.097 57.2082 54.3964C48.8647 68.0348 43.3229 83.2703 40.9855 99.2168C37.4726 94.5577 34.2306 89.5168 31.3138 84.1267C26.183 87.4733 21.1684 91.301 16.3398 95.6077C18.8195 78.6927 24.2828 62.4345 32.3973 47.5651C36.7311 39.619 41.8242 32.0693 47.6243 25.0289C48.2937 24.2111 48.9745 23.4058 49.6647 22.6016C52.5951 25.1251 55.5234 27.6487 58.4548 30.1722C61.4489 32.7512 64.4442 35.3291 67.4394 37.9112C67.8054 38.2229 68.1694 38.5397 68.5364 38.8545Z" fill="#FF4440" />
            <path d="M120.735 95.6077C115.906 91.3011 110.892 87.4734 105.761 84.1268C102.844 89.5169 99.6021 94.5577 96.0892 99.2168C93.7518 83.2702 88.2111 68.0348 79.8665 54.3964C77.2384 50.0971 74.3279 45.9557 71.1548 42.0035C70.2994 40.9409 69.4261 39.8899 68.5361 38.8546C68.9022 38.5398 69.2661 38.225 69.6332 37.9112C72.6305 35.3291 75.6257 32.748 78.6209 30.1722C81.5513 27.6455 84.4827 25.1251 87.4099 22.6016C88.1002 23.4027 88.781 24.2111 89.4503 25.0268C95.2514 32.0672 100.345 39.617 104.681 47.5651C112.793 62.4345 118.255 78.6929 120.735 95.6077Z" fill="#FF4440" />
            <path d="M57.624 38.8555H79.4512V58.4038H57.624V38.8555Z" fill="#FF4440" />
            <path d="M104.681 47.5657L79.8665 54.397C77.2384 50.0976 74.3279 45.9562 71.1548 42.0041L69.6332 37.9118L68.5361 34.9605L78.6209 30.1728L89.4503 25.0273C95.2515 32.0678 100.345 39.6175 104.681 47.5657Z" fill="#EA2F2F" />
            <path d="M68.5366 34.9603L67.4396 37.9116L65.92 42.0039C62.7449 45.956 59.8365 50.0975 57.2084 54.3968L32.3975 47.5655C36.7313 39.6194 41.8244 32.0697 47.6245 25.0293L58.4549 30.1726L68.5366 34.9603Z" fill="#EA2F2F" />
            <path d="M108.14 1.83311L68.5381 20.64L76.1454 41.0992L118.415 29.4646C123.943 27.9429 126.98 22.01 124.982 16.6356L121.493 7.25461C119.495 1.88017 113.32 -0.626647 108.14 1.83311Z" fill="#FF4440" />
            <path d="M28.9357 1.83311L68.5376 20.64L60.9303 41.0992L18.6616 29.4646C13.1335 27.9429 10.0964 22.01 12.095 16.6356L15.5827 7.25461C17.5813 1.88017 23.7558 -0.626647 28.9357 1.83311Z" fill="#FF4440" />
            <path d="M77.9936 28.8636C77.2741 28.8636 76.5681 28.5081 76.1529 27.8565C75.5035 26.841 75.7995 25.4919 76.8149 24.8425C77.1151 24.6511 84.2475 20.1123 92.4363 16.8002C104.241 12.025 112.125 12.2812 115.865 17.5615C116.562 18.5456 116.329 19.9083 115.345 20.6048C114.361 21.3014 112.999 21.0692 112.303 20.0851C107.232 12.9275 86.152 24.0571 79.1669 28.5206C78.8029 28.7528 78.3962 28.8636 77.9936 28.8636Z" fill="#EA2F2F" />
            <path d="M58.8661 28.8636C58.4634 28.8636 58.0566 28.7528 57.6927 28.5206C50.7077 24.0571 29.6272 12.9275 24.557 20.0851C23.8594 21.0692 22.4978 21.3014 21.5137 20.6049C20.5306 19.9073 20.2974 18.5457 20.9949 17.5615C24.7348 12.2812 32.6171 12.025 44.4233 16.8002C52.612 20.1112 59.7444 24.6511 60.0446 24.8425C61.0601 25.492 61.356 26.8411 60.7076 27.8565C60.2904 28.5081 59.5856 28.8636 58.8661 28.8636Z" fill="#EA2F2F" />
            <path d="M74.7475 13.6094H62.3275C58.9286 13.6094 56.1729 16.3651 56.1729 19.765V37.8085C56.1729 41.2084 58.9286 43.9641 62.3275 43.9641H74.7475C78.1464 43.9641 80.9022 41.2084 80.9022 37.8085V19.765C80.9022 16.3651 78.1464 13.6094 74.7475 13.6094Z" fill="#EA2F2F" />
            <path d="M57.624 58.4023H79.4512V137.871H57.624V58.4023Z" fill="#FF4440" />
            <path d="M57.624 58.4023H79.4512V68.0061H57.624V58.4023Z" fill="#EA2F2F" />
          </svg>
          <Text mt='1.5rem' textAlign={'center'}>Start Earning With Suta by Referring People</Text>
        </Flex>

        <div>
          <Heading mt='1.2rem' textAlign={'center'} textTransform='capitalize'>Your referral details</Heading>
          <Flex justify={'space-between'} align={'center'} textAlign={'center'} p='10px 15px' mt='.8rem' borderRadius={'14px'} bg={`${colors.primaryColor}`} color={`${colors.lightColor}`} cursor='pointer'> {linkToCopy} <MdContentCopy onClick={handleCopyClick} /></Flex>
          {linkCopied ? 'Copied!' : ''}
          <Flex justify={'space-between'} align={'center'} textAlign={'center'} p='10px 15px' mt='.8rem' borderRadius={'14px'} bg={`${colors.primaryColor}`} color={`${colors.lightColor}`} cursor='pointer'>{codeToCopy}<MdContentCopy onClick={handleCodeCopy} /></Flex>
          {codeCopied ? 'Copied!' : ''}
        </div>

        {/* <div>
          <Heading mt={'1.2rem'} textAlign={'center'}>Earnings</Heading>
          <Flex justify={'space-between'} align='center' p='10px 15px' mt='.8rem' borderRadius={'14px'} bg={`${colors.primaryColor}`} color={`${colors.lightColor}`}>
            <Text>Total cash earned</Text>
            <Text>₦0</Text>
          </Flex>
          <Flex justify={'space-between'} align='center' p='10px 15px' mt='.8rem' borderRadius={'14px'} bg={`${colors.primaryColor}`} color={`${colors.lightColor}`}>
            <Text>Total referrals</Text>
            <Text>0</Text>
          </Flex>
        </div> */}
      </Container>
      <DashboardFooter />
    </div>
  )
}

export default Referral