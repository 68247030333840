import styled from "styled-components";
import {colors} from "./theme";

export const InputContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    
    border-radius: 12px;
    background: #F5F7FB;
    box-shadow: 0px 4px 120px 0px rgba(127, 135, 158, 0.10);
    
    padding: 8px 10px;
    margin-bottom: 1rem;
  
  h3 {
    background: rgba(66, 164, 217, .16);
    color: ${colors.primaryColor};
    border-radius: 15px;
    width: 55px;
    margin-right: .6rem;
    /* opacity: 0.16; */
    padding: 10px 14px;

    @media (max-width: 992px) {
      padding: 9px 12px;
    }

  }
  
  input {
    background: transparent;
  }
  input:focus {
    border: none;
    outline: none;
  }
`;

export const Button = styled.button`
    place-content: center;
    border-radius: 10px;
    padding: 8px 18px;
    align-self: center;

    display: flex;
    align-items: center;
    justify-center: center;
    gap: .6rem;

    background: ${colors.primaryColor};
    color: ${colors.lightColor};
    text-align: center;

    svg {
        font-size: 20px;
        font-weight: 500;
    }

    @media (max-width: 992px) {
        padding: 9px 14px;
    }

`;

