import React from 'react';
import { ChakraProvider, CSSReset, extendTheme, Flex, Text, Button, Tooltip } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../styles/theme';
const theme = extendTheme();

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentRoute = location.pathname;
  return (
    <Flex
      as="nav"
      align="center"
      justify="center" 
      wrap="wrap"
      padding={{ base: '1rem', lg: "1.5rem"}}
      m={{ base: '.5rem', lg: '1rem'}}
      borderRadius={'.8rem'}
      bg={`${colors.primaryColor}`}
    >
      <Flex
        width={{ base: 'full', md: 'auto' }}
        alignItems="center"
        flexGrow={1}
      >
        <Tooltip label="See jobs you can carry out">
        <Button onClick={() => navigate("/dashboard/tasks/market")} bg={currentRoute != "/dashboard/tasks/market"? 'white' : '#A0AEC0'} color={`${colors.primaryColor}`} variant="solid" colorScheme="whiteAlpha" mr={{ base: 2, lg: 4}}>
          Market
        </Button>
        </Tooltip>
        <Tooltip label="See all task you have created">
        <Button onClick={() => navigate("/dashboard/tasks/tasks")} bg={currentRoute != "/dashboard/tasks/tasks"? 'white' : '#A0AEC0'} color={`${colors.primaryColor}`} variant="solid" colorScheme="whiteAlpha" mr={{ base: 2, lg: 4}}>
            My Tasks
        </Button>
        </Tooltip>
        <Tooltip label="Jobs you have to complete">
        <Button onClick={() => navigate("/dashboard/tasks/jobs")} bg={currentRoute != "/dashboard/tasks/jobs"? 'white' : '#A0AEC0'} color={`${colors.primaryColor}`} variant="solid" colorScheme="whiteAlpha" mr={{ base: 2, lg: 4}}>
           Jobs
        </Button>
        </Tooltip>
        <Tooltip label="View all settlements for job done">
        <Button onClick={() => navigate("/dashboard/tasks/settlements")} bg="white" color={`${colors.primaryColor}`} variant="solid" colorScheme="whiteAlpha" mr={{ base: 2, lg: 4}}>
          Settlements
        </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Navbar />
      {/* Your main content */}
    </ChakraProvider>
  );
};

export default App;
