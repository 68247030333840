import React from "react";
import { useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import { Container } from "../style";
import DashboardNav from "../../components/dashboard-nav";
import DashboardFooter from "../../components/dashboard-footer";
import { useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { addUserInfo } from "../../features/user/userSlice";


const DashboardWrapper = () => {
    const dispatch = useDispatch();
	const { user } = useSelector((store) => store.user);
	const navigate = useNavigate();

	useEffect(() => {
		if (user?.user_id) {
			const getUser = async () => {
				try {
					const resp = await axiosInstance.get("/api/v1/me");
					dispatch(addUserInfo(resp.data));
				} catch (error) {
					
					if (error.response && error.response.status === 401) {
						// Redirect to the login page if the API returns a 401 error
						navigate('/auth/login');
					  }
				}
			};
			getUser();
		}
	}, []);

    return (
        <Container>
            <DashboardNav />
            {/*<SmallSidebar />*/}
            <Outlet />
            <DashboardFooter />
        </Container>
    );
};

export default DashboardWrapper;