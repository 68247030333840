import { Flex } from '@chakra-ui/react'
import React from 'react'
import { dashNav } from "../../assets/dashboard";
import { RiArrowLeftLine } from 'react-icons/ri'
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom'
import { colors } from '../../styles/theme'
import Topnav from '../dashboard-nav/topnav';

const SubNav = ({title}) => {
  const navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  return (
    <Wrapper>
        <Topnav />
        <Flex justifyContent={'space-around'} pt='2rem'>
            <div onClick={handleClick} className='icon-wrapper'>
                <RiArrowLeftLine />
            </div>
            <h2 style={{ color: `${colors.lightColor}`, fontSize: '24px'}}>{title}</h2>
            <div></div>
        </Flex>
    </Wrapper>
  )
}

export default SubNav


const Wrapper = styled.div`
  padding: 0 4rem;
  padding-bottom: 6rem;
  background-image: url('${dashNav}');
  background-size: cover;
  background-position: center;

  @media(max-width: 992px) {
    padding: 0 2rem;
    padding-bottom: 3rem;
  }


  .icon-wrapper {
    background: ${colors.lightColor};
    border-radius: 3px;
    padding: 8px;
    cursor: pointer;

    svg {
      font-size: 18px;
    }
  }
`;