import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Box, Text, Heading, Container, Flex, Link } from '@chakra-ui/react'
import SubNav from '../../../components/sub-nav'
import { colors } from '../../../styles/theme'
import { debit, transfer, ussd, voucher } from '../../../assets/dashboard'
import styled from '@emotion/styled'
import { PaystackButton } from 'react-paystack'


const PaymentMethod = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dataToReceive = state && state.data;
  
    // Use dataToReceive in your component
    // // console.log(dataToReceive.ussd_array);

    const handleClick = async () => {
        navigate('/dashboard/fund-wallet/ussd', {state: { data: dataToReceive } })
    }
    const methods = [
        // {
        //     img: transfer,
        //     name: 'Bank Transfer',
        //     path: 'transfer',
        //     target: '_blank'
        // },
        {
            img: debit,
            name: 'Debit card / Transfer',
            path: `${dataToReceive.payment_link}`,
            target: '_blank',
        },
    ]

  return (
    <Wrapper>
        <SubNav title={'Payment Method'} />
        <Container maxW={{ base: 'sm', lg: 'lg'}}>
            <Card 
                maxW={{ base: 'sm', lg: 'md' }}  
                textAlign='center' 
                alignSelf={'center'} 
                my={{ base: '2rem', lg: '4rem' }}
                boxShadow={'lg'} 
                borderRadius='lg'
                display='flex'
                justify={'center'}
                align='center'
                flexDir={'column'}
            >
                <CardHeader>
                    <Heading fontFamily={'Raleway'} fontSize={{ base: '24px', lg: '32px' }}>Fund Wallet</Heading>
                </CardHeader>
                <Box width={'80%'} height='3px' bg={`${colors.primaryColor}`} />
                <CardBody textAlign={'center'} mb='1.4rem' color={`${colors.primaryColor}`}>
                    <Text fontFamily='Raleway' fontSize='14px' lineHeight='24px' fontWeight='600' my='1rem'>Choose payment method below</Text>

                    {methods.map((item, index) => (
                        <Link 
                            key={index}
                            href={`${item.path}`}
                            textDecoration='none'
                            target={`${item.target}`}
                        >
                            <Flex
                                w='280px' 
                                h='47px' 
                                borderRadius='20px' 
                                border={`2px solid ${colors.primaryColor}`} 
                                bg={'transparent'}

                                justify='start'
                                align='center'
                                gap='1rem'
                                mb={'1rem'}
                                px='1rem'
                            >
                                <img src={item.img} alt={item.name} />
                                <Text fontFamily='Raleway' fontSize='15px' lineHeight='normal' fontWeight='700'>{item.name}</Text>
                            </Flex>
                        </Link>
                    ))}
                        {/* <Link as={'button'} href={'ussd'} textDecoration='none' onClick={() => handleClick()}>
                            <Flex w='280px' h='47px' borderRadius='20px' border={`2px solid ${colors.primaryColor}`} bg={'transparent'}
                                justify='start'
                                align='center'
                                gap='1rem'
                                mb={'1rem'}
                                px='1rem'
                            >
                                <img src={ussd} alt={'USSD'} />
                                <Text fontFamily='Raleway' fontSize='15px' lineHeight='normal' fontWeight='700'>USSD</Text>
                            </Flex>
                        </Link> */}
                        <Link href={'voucher'} textDecoration='none'>
                            <Flex w='280px' h='47px' borderRadius='20px' border={`2px solid ${colors.primaryColor}`} bg={'transparent'}
                                justify='start'
                                align='center'
                                gap='1rem'
                                mb={'1rem'}
                                px='1rem'
                            >
                                <img src={voucher} alt={'Voucher'} />
                                <Text fontFamily='Raleway' fontSize='15px' lineHeight='normal' fontWeight='700'>Voucher</Text>
                            </Flex>
                        </Link>
                </CardBody>
            </Card>
        </Container>
    </Wrapper>
  )
}

export default PaymentMethod


const Wrapper = styled.div`
    background: #F3F3F3;
    /* padding: 1rem 0; */
    padding-bottom: 4rem;
`;