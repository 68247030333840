import { configureStore , createSlice } from "@reduxjs/toolkit";
import userSlice from "../features/user/userSlice";
import adminSlice from "../features/admin/adminSlice";
import loadingReducer from './reducers/loading';

export const store = configureStore({
	reducer: {
		user: userSlice,
		admin: adminSlice,
		loading: loadingReducer,
	},
});
