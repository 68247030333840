import { Container, Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import SubNav from '../../../components/sub-nav'
import { BsFillChatRightTextFill, BsFillTelephoneFill, BsWhatsapp } from 'react-icons/bs'
import { RiMailFill } from 'react-icons/ri'
import { colors } from '../../../styles/theme'

const contacts =[
  {
    name: 'call',
    icon: <BsFillTelephoneFill />,
    url: 'tel:09036898349',
  },
  {
    name: 'email',
    icon: <RiMailFill />,
    url: 'mailto:support@suta.ng',
  },
  {
    name: 'whatsapp',
    icon: <BsWhatsapp />,
    url: 'https://wa.me/+2349036898349',
  },
  {
    name: 'live chat',
    icon: <BsFillChatRightTextFill />,
    url: '',
  },
]
const Support = () => {
  return (
    <div>
        <SubNav title={'Support'} />
        <Container my='2rem' maxW={{ base: 'sm', lg: 'lg'}}>
          {contacts.map((item, index) => (
            <Flex key={index} 
              textAlign='start'
              justify={'start'} 
              align='center'
              gap={'2rem'}

              fontSize={'18px'}
              borderRadius='20px' 
              p='1rem 1.5rem' 
              w={{ base: '100%', lg: '600px' }} 
              bg={'#F5F7FB'} 
              my='.5rem'

              as={'a'}
              href={item.url}
            >
              <Flex justify={'center'} align='center' fontSize={'24px'} w='60px' h='60px' borderRadius='50%' bg={`${colors.primaryColor}`} color={`${colors.lightColor}`}>
                {item.icon}
              </Flex>
              <Text textTransform={'capitalize'}>{item.name}</Text>
            </Flex>
          ))}
        </Container>
    </div>
  )
}

export default Support