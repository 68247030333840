import { Container, Stack, Button, Center, Spinner } from '@chakra-ui/react'
import { InputContainer } from '../../../styles/login.style'
import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import DashboardFooter from '../../../components/dashboard-footer'
import SubNav from '../../../components/sub-nav'
import { user } from '../../../assets/auth';
import { userImg } from '../../../assets/dashboard'
import { BsArrowRight } from 'react-icons/bs'
import { colors } from '../../../styles/theme'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { decrement, increment } from '../../../store/reducers/loading'

const EditProfile = () => {
    const loadingCount = useSelector((state) => state.loading.count);
    const dispatch = useDispatch();
    const isLoading = () => {
        // console.log(loadingCount);
        return loadingCount > 0;
    }
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [selectedImage, setSelectedImage] = useState(null);
    const [profileImg, setProfileImg] = useState(userImg)
    const navigate = useNavigate()

    const handleGetData = async () => {
        const token = `Bearer ${Cookies.get('Token')}`
        try {
            dispatch(increment());
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
            });

            const data = await response.json();
            dispatch(decrement());
            setFirstName(data.data.user.first_name);
            setLastName(data.data.user.last_name);
            setProfileImg(data.data.user.profile_image)
            // // console.log(data)


        } catch (err) {
            dispatch(decrement());
            // Handle errors
            // console.log(err)
            if (err.response && err.response.status === 401) {
                // Redirect to the login page if the API returns a 401 error
               navigate("/auth/login");
            }
        }
    }
    useEffect(() => {
        handleGetData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            dispatch(increment());
            const token = `Bearer ${Cookies.get('Token')}`
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me/3/update`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    profile_image: profileImg,
                }),
            });
            const data = await response.json();
            dispatch(decrement());
            // console.log(data)
            navigate("/dashboard/my-account");

        } catch (error) {
            dispatch(decrement());
            if (error.response && error.response.status === 401) {
                // Redirect to the login page if the API returns a 401 error
               navigate("/auth/login");
            }
        }
    }


    function handleImageUpload(e) {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };

            reader.readAsDataURL(file);
        }
    }

    return (
        <>
            <Wrapper>
                <SubNav title={'Edit Profile'} />
                <Container maxW={'lg'} my='2rem'>
                    <ImgWrapper>
                        {selectedImage ? (
                            <img
                                src={selectedImage}
                                alt="Selected Preview"
                            />
                        ) :
                            <img
                                src={profileImg == null ? userImg : profileImg}
                                alt="Selected Preview"
                            />
                        }
                        {/* Image upload input */}
                        <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ display: 'none' }}
                            id="image-upload-input"
                        />

                        {/* Image upload button (icon) */}
                        <label htmlFor="image-upload-input">
                            <div className='camera-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                                    <path d="M3.47181 3.47181V0H5.78635V3.47181H9.25815V5.78635H5.78635V9.25815H3.47181V5.78635H0V3.47181H3.47181ZM6.94361 10.4154V6.94361H10.4154V3.47181H18.5163L20.6341 5.78635H24.3027C25.5756 5.78635 26.6172 6.82789 26.6172 8.10088V21.9881C26.6172 23.2611 25.5756 24.3027 24.3027 24.3027H5.78635C4.51335 24.3027 3.47181 23.2611 3.47181 21.9881V10.4154H6.94361ZM15.0445 20.8308C18.2386 20.8308 20.8308 18.2386 20.8308 15.0445C20.8308 11.8504 18.2386 9.25815 15.0445 9.25815C11.8504 9.25815 9.25815 11.8504 9.25815 15.0445C9.25815 18.2386 11.8504 20.8308 15.0445 20.8308ZM11.3412 15.0445C11.3412 17.0929 12.9961 18.7478 15.0445 18.7478C17.0929 18.7478 18.7478 17.0929 18.7478 15.0445C18.7478 12.9961 17.0929 11.3412 15.0445 11.3412C12.9961 11.3412 11.3412 12.9961 11.3412 15.0445Z" fill="#42A4D9" />
                                </svg>
                            </div>
                        </label>
                    </ImgWrapper>

                    <form onSubmit={handleSubmit}>
                        <InputContainer style={{ margin: '1.2rem 0' }}>
                            <img src={user} alt={'icon'} />
                            <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                            style={{"flex": "1"}}
                                type={'text'}
                                placeholder={''}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />

                            {/* {!isValid && <p className="error">Invalid input. Please enter a valid email or phone number.</p>} */}
                        </InputContainer>
                        <InputContainer style={{ margin: '1.2rem 0' }}>
                            <img src={user} alt={'icon'} />
                            <input autocomplete="off" name={Math.random().toString(36).substr(2, 10)}
                            style={{"flex": "1"}}
                                type={'text'}
                                placeholder={''}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />

                            {/* {!isValid && <p className="error">Invalid input. Please enter a valid email or phone number.</p>} */}
                        </InputContainer>

                        <Stack spacing={10} pt={2}>
                            {isLoading() ?
                                <Button
                                    loadingText="Loading"
                                    size="lg"
                                    bg={'blue.400'}
                                    type='submit'
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}>
                                    <Spinner />
                                </Button> :

                                <Button
                                    loadingText="Loading"
                                    size="lg"
                                    bg={'blue.400'}
                                    type='submit'
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}>
                                    Save Changes <BsArrowRight style={{ fontSize: '22px', marginLeft: '.7rem', fontWeight: '600' }} />
                                </Button>

                            }
                        </Stack>
                    </form>
                    <Center>
                        <Button variant={'ghost'} as={'a'} href='/dashboard/my-account' textAlign='center' color={`${colors.primaryColor}`} my='1.5rem'>Go back</Button>
                    </Center>
                </Container>

            </Wrapper>
            <DashboardFooter />
        </>
    )
}

export default EditProfile

const Wrapper = styled.div`
    background: #F3F3F3;
    /* padding: 1rem 0; */
    padding-bottom: 4rem;

    form {
        margin-top: 2.4rem 0;
    }

`;

const ImgWrapper = styled.div`
    position: relative;
    display: flex;
    justify: center;
    align: center;

    text-align: center;
    img {
        margin: auto;
        width: 182px;
        height: 184px;
        border-radius: 50%;
    }

    .camera-icon {
        position: absolute;
        bottom: -10px;
        right: 150px;

        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: ${colors.lightBg};

        display: flex;
        cursor: pointer;

        svg {
            margin: auto;
        }
    }

`;